import { Platform } from "react-native";

export const getBlobFroUri = async (uri) => {
  if (Platform.OS === "android") {
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    return blob;
  } else if (Platform.OS === "ios") {
    try {
      const response = await fetch(uri);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation: ",
        error
      );
      throw error;
    }
  }
};
