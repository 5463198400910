import React, { useState, useRef, useEffect, useCallback } from "react";
import { View, Pressable, Animated } from "react-native";

const RecordButton = ({
  isRecording,
  setIsRecording,
  updateRecordingStatus,
}) => {
  const animation = useRef(new Animated.Value(0)).current;
  const throbAnimation = useRef(new Animated.Value(0)).current;
  const scaleAnimation = useRef(new Animated.Value(0)).current;

  const startRecording = useCallback(() => {
    setIsRecording(true);
    Animated.sequence([
      Animated.timing(animation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }),
      Animated.timing(animation, {
        toValue: 1,
        duration: 500,
        delay: 200,
        useNativeDriver: false,
      }),
    ]).start();
  }, [animation, setIsRecording]);

  const stopRecording = useCallback(() => {
    setIsRecording(false);
    Animated.sequence([
      Animated.timing(animation, {
        toValue: 0,
        duration: 500,
        useNativeDriver: false,
      }),
      Animated.timing(animation, {
        toValue: 0,
        duration: 500,
        delay: 200,
        useNativeDriver: false,
      }),
    ]).start();
  }, [animation, setIsRecording]);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording, startRecording, stopRecording]);

  const onStopClick = () => {
    updateRecordingStatus(false);
    stopRecording();
  };

  const onStartClick = () => {
    updateRecordingStatus(true);
    startRecording();
  };

  useEffect(() => {
    if (isRecording) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(throbAnimation, {
            toValue: 1,
            duration: 2000,
            useNativeDriver: true,
          }),
          Animated.timing(throbAnimation, {
            toValue: 0,
            duration: 2000,
            useNativeDriver: true,
          }),
        ])
      ).start();

      Animated.loop(
        Animated.sequence([
          Animated.timing(scaleAnimation, {
            toValue: 1,
            duration: 2000,
            useNativeDriver: false,
          }),
          Animated.timing(scaleAnimation, {
            toValue: 0,
            duration: 2000,
            useNativeDriver: false,
          }),
        ])
      ).start();
    } else {
      throbAnimation.setValue(0);
      scaleAnimation.setValue(0);
    }
  }, [isRecording, throbAnimation, scaleAnimation]);

  const scaleX = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.625],
  });

  const scaleY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.625],
  });

  const borderRadius = animation.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [20, 20, 5],
  });

  const buttonColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ["white", "red"],
  });

  const scale = scaleAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 1.1],
  });

  return (
    <Pressable
      onPress={isRecording ? onStopClick : onStartClick}
      style={{
        borderWidth: 2,
        borderColor: "white",
        borderRadius: 50,
        padding: 5,
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Animated.View
        style={{
          width: 40,
          height: 40,
          backgroundColor: buttonColor,
          borderRadius,
          transform: [{ scaleX }, { scaleY }, { scale }],
        }}
      />
    </Pressable>
  );
};

export default RecordButton;
