import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Modal,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Button, { ButtonVariant } from "./Button";

type FeedbackFormProps = {
  isVisible: boolean;
  onClose: () => void;
  userId: string;
  onSubmit: () => void; // New callback prop
};

const FeedbackForm = ({
  isVisible,
  onClose,
  userId,
  onSubmit,
}: FeedbackFormProps) => {
  console.log("userId", userId);
  const [feedback, setFeedback] = useState("");
  const [question, setQuestion] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestion = async () => {
      console.log("fetching question");
      const db = getFirestore();
      const q = query(collection(db, "feedback"), where("active", "==", true));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot);
      if (!querySnapshot.empty) {
        const feedbackData = querySnapshot.docs[0].data();
        setQuestion(feedbackData.text);
        setFeedbackId(querySnapshot.docs[0].id);
      }
      setLoading(false);
    };
    fetchQuestion();
  }, []);

  const handleFeedbackSubmit = async () => {
    const db = getFirestore();
    console.log("Submitting feedback:", {
      userId,
      feedbackId,
      response: feedback,
    });
    await addDoc(collection(db, "response"), {
      userId,
      feedbackId,
      response: feedback,
      createdAt: new Date(),
    });
    onClose();
    onSubmit(); // Call the callback
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={isVisible}
      onRequestClose={onClose}
      onDismiss={onClose}
      presentationStyle="pageSheet"
    >
      <View style={styles.fullscreenModal}>
        <View style={styles.modalView}>
          {loading ? (
            <ActivityIndicator size="large" color="#0000ff" />
          ) : (
            <>
              <Text style={styles.modalText}>
                Become a premium user by responding to one feedback question
                each month.
              </Text>
              <Text style={styles.questionText}>{question}</Text>
              <TextInput
                style={styles.inputMultiline}
                value={feedback}
                onChangeText={setFeedback}
                multiline={true}
              />
              <View style={styles.buttonGroup}>
                <Button
                  title="Submit"
                  onPress={handleFeedbackSubmit}
                  variant={ButtonVariant.Primary}
                />
                <Button
                  title="Cancel"
                  onPress={onClose}
                  variant={ButtonVariant.Secondary}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  fullscreenModal: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    paddingTop: 50,
  },
  modalView: {
    width: "90%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    alignItems: "flex-start",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "left",
  },
  questionText: {
    marginBottom: 10,
    textAlign: "left",
  },
  inputMultiline: {
    width: "100%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginBottom: 20,
    height: 100,
    textAlignVertical: "top",
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
});

export default FeedbackForm;
