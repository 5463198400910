// FormInput.js
import React from "react";
import { Text, TextInput, View, StyleSheet } from "react-native";

const FormInput = React.forwardRef(
  (
    {
      label,
      placeholder,
      onChangeText,
      secureTextEntry,
      onSubmitEditing,
      returnKeyType = "next",
    },
    ref
  ) => {
    // If there's no value (which would be the scheduledStartTime in this case),
    // set it to the current time

    return (
      <View style={styles.formRow}>
        <Text style={styles.label}>{label}</Text>
        <TextInput
          placeholder={placeholder}
          style={styles.textInput}
          onChangeText={onChangeText}
          autoCapitalize="none"
          autoCorrect={false}
          secureTextEntry={secureTextEntry}
          onSubmitEditing={onSubmitEditing}
          returnKeyType={returnKeyType}
          ref={ref} // This is how you pass the ref down to the TextInput
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  formRow: {
    flexDirection: "row",
    alignItems: "center",

    width: "100%",
    gap: 20,
    marginBottom: 20,
    // paddingRight: 50,
  },
  label: {
    textAlign: "left",
    width: "30%",
  },
  textInput: {
    padding: 10,
    height: 40,
    width: "60%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
  },
});

export default FormInput;
