import React, { useState, useEffect } from "react";
import {
  Modal,
  View,
  Text,
  Button as RNButton, // Alias the React Native Button
  StyleSheet,
  ScrollView,
  Switch,
  TouchableOpacity,
  Platform,
  TextInput,
} from "react-native";
import Colors from "./Colors";
import { Alert } from "react-native";
import { Ionicons, Feather } from "@expo/vector-icons";
import Button, { ButtonVariant } from "./Button"; // Import the local Button component
import FeedbackForm from "./FeedbackForm";
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { BASE_URL } from "../config/constants";

type GenerateVideoProps = {
  isVisible: boolean;
  onClose: () => void;
  projectId: string;
  userId: string;
  onCloseAndRefresh: () => void; // New prop to refresh data after closing
  isPreviewVideo?: boolean;
};

const GenerateVideo = ({
  isVisible,
  onClose,
  projectId,
  userId,
  onCloseAndRefresh,
  isPreviewVideo = false,
}: GenerateVideoProps) => {
  const [quality, setQuality] = useState("hd");
  const [fps, setFps] = useState(12);
  const [speed, setSpeed] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [deflicker, setDeflicker] = useState(false);
  const [removeBlackFrames, setRemoveBlackFrames] = useState(false);
  const [addPtlWatermark, setAddPtlWatermark] = useState(false); // New state for PTL Watermark
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [is4kLocked, setIs4kLocked] = useState(true);
  const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] =
    useState(false);
  const [isAdvancedSettingsLocked, setIsAdvancedSettingsLocked] =
    useState(true);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    console.log("GenerateVideo useEffect");
    console.log("isVisible:", isVisible);
    console.log("isPreviewVideo:", isPreviewVideo);
    if (isVisible) {
      setIsGenerating(false);
      if (isPreviewVideo) {
        fetchVideoSettings();
      }
    }
  }, [isVisible, isPreviewVideo]);

  const fetchVideoSettings = async () => {
    console.log("Fetching video settings...");
    const db = getFirestore();
    const videosQuery = query(
      collection(db, "videos"),
      where("projectId", "==", projectId),
      where("userId", "==", userId),
      where("isPreview", "==", true)
    );
    const videosSnapshot = await getDocs(videosQuery);
    console.log("Videos snapshot:", videosSnapshot);
    if (!videosSnapshot.empty) {
      const videoDoc = videosSnapshot.docs[0];
      const videoData = videoDoc.data();
      if (videoData.quality) {
        setQuality(videoData.quality);
      }
      setFps(videoData.fps);
      setSpeed(videoData.speed);
      setDeflicker(videoData.deflicker);
      setRemoveBlackFrames(videoData.removeBlackFrames);

      // Check if rotation is set in videoData
      if (videoData.rotation !== undefined) {
        setRotation(videoData.rotation);
      } else {
        // If rotation is not set in videoData, check the project's rotation
        const projectRef = doc(db, "projects", projectId);
        const projectDoc = await getDoc(projectRef);
        if (projectDoc.exists()) {
          const projectData = projectDoc.data();
          const projectRotation = projectData.rotation;
          if ([0, 90, 180, 270].includes(projectRotation)) {
            setRotation(projectRotation);
          } else {
            setRotation(0); // Default to 0 if project rotation is not valid
          }
        } else {
          setRotation(0); // Default to 0 if project document doesn't exist
        }
      }
    }
  };

  const checkFeedbackAndResponses = async () => {
    console.log("Checking feedback and responses...");
    const db = getFirestore();
    const feedbackQuery = query(
      collection(db, "feedback"),
      where("active", "==", true)
    );
    const feedbackSnapshot = await getDocs(feedbackQuery);
    console.log("Feedback:", feedbackSnapshot);
    if (!feedbackSnapshot.empty) {
      const feedbackDoc = feedbackSnapshot.docs[0];
      const feedbackData = feedbackDoc.data();
      const feedbackId = feedbackDoc.id;
      console.log("Feedback ID:", feedbackId);
      console.log("User ID:", userId);
      const responsesQuery = query(
        collection(db, "response"),
        where("userId", "==", userId),
        where("feedbackId", "==", feedbackId)
      );

      const responsesSnapshot = await getDocs(responsesQuery);
      console.log("Responses size:", responsesSnapshot.size);
      responsesSnapshot?.forEach((doc) => {
        console.log("Response document:", doc.id, doc.data());
      });
      console.log("Responses empty?:", responsesSnapshot.empty);

      if (!responsesSnapshot.empty) {
        console.log("Setting 4k unlocked");
        setIs4kLocked(false);
        setIsAdvancedSettingsLocked(false);
      } else {
        console.log("Setting 4k locked");
        setIs4kLocked(true);
        setIsAdvancedSettingsLocked(true);
      }
    } else {
      console.log("2- Setting 4k unlocked");
      setIs4kLocked(false);
      setIsAdvancedSettingsLocked(false);
    }
  };

  useEffect(() => {
    checkFeedbackAndResponses();
  }, [projectId, userId, isVisible]);

  const handleCancel = () => {
    setIsFeedbackVisible(false);
    onClose();
  };

  const generateVideoUrl = async () => {
    setIsGenerating(true);
    const imagesFolder = "images";
    const isPreview = isPreviewVideo;
    const url = `${BASE_URL}/generateProjectVideoBatch?projectId=${projectId}&userId=${userId}&imagesFolder=${imagesFolder}&isPreview=${isPreview}&quality=${quality}&fps=${fps}&speed=${speed}&rotation=${rotation}&deflicker=${deflicker}&removeBlackFrames=${removeBlackFrames}&watermarked=${addPtlWatermark}`; // Updated URL
    console.log("Generate video URL:", url);
    const response = await fetch(url);
    const data = await response.json();
    console.log("Data:", data);
    onClose();
    setIsGenerating(false);
  };

  const renderOptionButtons = (options, selectedValue, setValue) => {
    return options.map((option) => {
      if (isPreviewVideo && option.value === "max") {
        return null;
      }
      const isLocked = option.value === "4k" && is4kLocked;
      return (
        <TouchableOpacity
          key={option.value}
          style={[
            styles.optionButton,
            selectedValue === option.value && styles.selectedOptionButton,
            isLocked && { backgroundColor: "#b0b0b0" },
          ]}
          onPress={() => {
            if (isLocked) {
              setIsFeedbackVisible(true);
            } else {
              setValue(option.value);
            }
          }}
        >
          <View style={styles.optionContent}>
            <Text
              style={[
                styles.optionButtonText,
                selectedValue === option.value &&
                  styles.selectedOptionButtonText,
                isLocked && { marginRight: 8 },
              ]}
            >
              {option.label}
            </Text>
            {isLocked && <Ionicons name="lock-closed" size={16} color="gold" />}
          </View>
        </TouchableOpacity>
      );
    });
  };

  const refetchData = async () => {
    await checkFeedbackAndResponses();
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={isVisible}
      onRequestClose={onClose}
      presentationStyle="pageSheet"
    >
      <ScrollView contentContainerStyle={styles.modalContainer}>
        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableOpacity>
          <Text style={styles.header}>Generate Video</Text>

          <Text>FPS</Text>
          <View style={styles.optionsContainer}>
            {renderOptionButtons(
              [
                { label: "12", value: 12 },
                { label: "24", value: 24 },
                { label: "30", value: 30 },
              ],
              fps,
              setFps
            )}
          </View>

          <Text>Speed</Text>
          <View style={styles.optionsContainer}>
            {renderOptionButtons(
              [
                { label: "1x", value: 1 },
                { label: "2x", value: 2 },
                { label: "8x", value: 8 },
                { label: "32x", value: 32 },
              ],
              speed,
              setSpeed
            )}
          </View>

          <Text>
            Rotation <Feather name={"rotate-cw"} size={16} color="black" />
          </Text>
          <View style={styles.optionsContainer}>
            {renderOptionButtons(
              [
                { label: "0°", value: 0 },
                { label: "90°", value: 90 },
                { label: "180°", value: 180 },
                { label: "270°", value: 270 },
              ],
              rotation,
              setRotation
            )}
          </View>

          <TouchableOpacity
            style={styles.advancedSettingsButton}
            onPress={() => {
              if (isAdvancedSettingsLocked) {
                setIsFeedbackVisible(true);
              } else {
                setIsAdvancedSettingsVisible(!isAdvancedSettingsVisible);
              }
            }}
          >
            <Text style={styles.advancedSettingsText}>Advanced Settings</Text>
            <Ionicons
              name={isAdvancedSettingsVisible ? "chevron-up" : "chevron-down"}
              size={20}
              color="black"
            />
            {isAdvancedSettingsLocked && (
              <Ionicons name="lock-closed" size={16} color="gold" />
            )}
          </TouchableOpacity>

          {isAdvancedSettingsVisible && (
            <View style={styles.advancedSettingsContainer}>
              <Text>Quality</Text>
              <View style={styles.optionsContainer}>
                {renderOptionButtons(
                  [
                    { label: "SD", value: "sd" },
                    { label: "HD", value: "hd" },
                    { label: "4k", value: "max" },
                  ],
                  quality,
                  setQuality
                )}
              </View>
              {quality === "max" && (
                <View style={styles.warningContainer}>
                  <Ionicons name="warning" size={24} color="white" />
                  <Text style={styles.warningText}>
                    Warning: 4k videos can take a very long time to generate
                  </Text>
                </View>
              )}
              <View style={styles.switchContainer}>
                <Text>Deflicker</Text>
                <Switch value={deflicker} onValueChange={setDeflicker} />
              </View>
              <View style={styles.switchContainer}>
                <Text>Remove Black Frames</Text>
                <Switch
                  value={removeBlackFrames}
                  onValueChange={setRemoveBlackFrames}
                />
              </View>
              <View style={styles.switchContainer}>
                <Text>Add ptl.camera Watermark</Text>
                <Switch
                  value={addPtlWatermark}
                  onValueChange={setAddPtlWatermark}
                />
              </View>
            </View>
          )}

          <View style={styles.generateButtonContainer}>
            <Button
              title={isPreviewVideo ? "Preview" : "Generate"}
              onPress={generateVideoUrl}
              disabled={isGenerating}
            />
          </View>
          <FeedbackForm
            isVisible={isFeedbackVisible}
            userId={userId}
            onClose={() => {
              setIsFeedbackVisible(false);
              onClose();
              onCloseAndRefresh();
            }}
            onSubmit={refetchData}
          />
        </View>
      </ScrollView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: "flex-start",
    backgroundColor: "white",
  },
  modalContent: {
    width: "100%",
    padding: 20,
    backgroundColor: "white",
  },
  closeButton: {
    alignSelf: "flex-end",
    padding: 10,
  },
  closeButtonText: {
    color: "blue",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  optionsContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  optionButton: {
    padding: 10,
    margin: 5,
    borderRadius: 5,
    backgroundColor: Colors.disabled,
  },
  selectedOptionButton: {
    backgroundColor: Colors.accent1,
  },
  optionContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  optionButtonText: {
    fontSize: 16,
  },
  selectedOptionButtonText: {
    color: "white",
  },
  switchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  generateButtonContainer: {
    marginTop: 20,
  },
  advancedSettingsButton: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  advancedSettingsText: {
    marginRight: 8,
  },
  advancedSettingsContainer: {
    marginBottom: 20,
  },
  warningContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "orange",
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  warningText: {
    color: "white",
    marginLeft: 10,
  },
});

export default GenerateVideo;
