import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import * as Battery from "expo-battery";
import { Camera, CameraType, FlashMode } from "expo-camera";
import { useKeepAwake } from "expo-keep-awake";
import { getAuth } from "firebase/auth";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  ref as refStorage,
  uploadBytes,
  listAll,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useState, useEffect, useRef, useCallback } from "react"; // Added useRef
import ReactInterval from "react-interval";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Timestamp } from "firebase/firestore";

import { CountdownTimer } from "./CountdownTimer";
import { TimeLimit } from "./TimeLimit";
import { convertSecondsToTimeLimit } from "./utils/convertSecondsToTimeLimit";
import { convertTimeLimitToSeconds } from "./utils/convertTimeLimitToSeconds";
import { formatLastImageTime } from "./utils/formatLastImageTime";
import { db } from "../../../firebaseConfig";
import { getBlobFroUri } from "../../utils/getBlobFromUri";
import { pad } from "../../utils/pad";
import { getData, removeData, storeData } from "../../utils/storage";
import RecordButton from "../RecordButton";
import ScheduleModal from "../ScheduleModal";

const auth = getAuth();
const storage = getStorage();

const updateBatteryLevel = async ({ projectId }) => {
  const batteryLevel = await Battery.getBatteryLevelAsync();
  const batteryPercentage = Math.round(batteryLevel * 100);

  const projectRef = doc(db, "projects", projectId);
  await updateDoc(projectRef, { batteryLevel: batteryPercentage });
};

const ProjectCamera = ({ route }) => {
  const [flashOn, setFlashOn] = useState(false);
  const [overlayStatus, setOverlayStatus] = useState(false);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [cameraType, setCameraType] = useState(CameraType.back);
  const [isRecording, setIsRecording] = useState(false);
  const [timeLimit, setTimeLimit] = useState("1hr");
  const [isCameraReady, setIsCameraReady] = useState(false);
  const projectId = route.params.id;

  const [countdown, setCountdown] = useState(null);
  const [lastImageTime, setLastImageTime] = useState(null);
  const userId = auth.currentUser.uid;

  // const [projectId, setProjectId] = useState(null);
  const navigation = useNavigation();

  const cameraRef = useRef(null);

  const folderPath = `${userId}/${projectId}/images`;
  useKeepAwake();

  const window = useWindowDimensions();
  const [bottomBarHeight, setBottomBarHeight] = useState(
    window.height > window.width ? 160 : 80
  );

  const zoomLevels = {
    0: "1",
    0.01: "2",
    0.03: "3",
  };

  const [zoom, setZoom] = useState(0); // Add zoom state

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [scheduledStartTime, setScheduledStartTime] = useState(null);

  const handleTimeLimitLongPress = () => {
    setIsScheduleModalVisible(true);
  };

  const handleSchedule = (date) => {
    setScheduledStartTime(date);
    updateProjectDoc({
      scheduledStartTime: Timestamp.fromDate(date),
    });
  };

  useEffect(() => {
    setBottomBarHeight(window.height > window.width ? 160 : 80);
  }, [window.height, window.width]);

  useEffect(() => {
    console.log(":::permission", permission);
    if (!permission) {
      requestPermission();
    }
  }, [permission, requestPermission]);

  useEffect(() => {
    if (isRecording) {
      const seconds = convertTimeLimitToSeconds(timeLimit);
      setCountdown(seconds);
    }
  }, [isRecording, timeLimit]);

  // useEffect(() => {
  //   const fetchProjectId = async () => {
  //     const fetchedProjectId = await getData("projectId");
  //     setProjectId(fetchedProjectId);
  //   };

  //   fetchProjectId();
  // }, []);

  const updateProjectDoc = useCallback(
    async (updateObject) => {
      if (projectId && userId) {
        const projectRef = doc(db, "projects", projectId);
        await updateDoc(projectRef, updateObject);
      }
    },
    [projectId, userId]
  );

  const fetchFileCount = useCallback(async () => {
    try {
      const listRef = refStorage(storage, folderPath);
      const fileNames = await listAll(listRef);
      const fileCount = fileNames.items.length;
      return fileCount;
    } catch (error) {
      console.log("Failed to fetch file count: ", error);
      return 0;
    }
  }, [folderPath]);

  const timeLimits = {
    "24hr": "1hr",
    "1hr": "5min",
    "5min": "30sec",
    "30sec": "24hr",
  };

  const handleTimeLimitClick = async () => {
    if (isRecording) return; // Prevent changing time limit while recording

    const newTimeLimit = timeLimits[timeLimit] || "24hr";
    setTimeLimit(newTimeLimit);

    await updateProjectDoc({
      // Convert the time limit to seconds before storing
      timeLimit: convertTimeLimitToSeconds(newTimeLimit),
    });

    // Adjust countdown based on last image time
    if (lastImageTime) {
      const currentTime = Date.now();
      const timeLimitInSeconds = convertTimeLimitToSeconds(newTimeLimit);
      const timeElapsed = (currentTime - lastImageTime) / 1000;
      const adjustedCountdown = Math.round(
        Math.max(timeLimitInSeconds - timeElapsed, 0)
      );

      console.log("handleTimeLimitClick - currentTime:", currentTime);
      console.log("handleTimeLimitClick - lastImageTime:", lastImageTime);
      console.log(
        "handleTimeLimitClick - timeLimitInSeconds:",
        timeLimitInSeconds
      );
      console.log("handleTimeLimitClick - timeElapsed:", timeElapsed);
      console.log(
        "handleTimeLimitClick - adjustedCountdown:",
        Math.round(adjustedCountdown)
      );

      setCountdown(Math.round(adjustedCountdown));
    } else {
      setCountdown(convertTimeLimitToSeconds(newTimeLimit));
    }
  };

  useEffect(() => {
    if (isRecording) {
      const currentTime = Date.now();
      const timeLimitInSeconds = convertTimeLimitToSeconds(timeLimit);
      if (lastImageTime) {
        const timeElapsed = (currentTime - lastImageTime) / 1000;
        const adjustedCountdown = Math.max(timeLimitInSeconds - timeElapsed, 0);

        console.log("useEffect - currentTime:", currentTime);
        console.log("useEffect - lastImageTime:", lastImageTime);
        console.log("useEffect - timeLimitInSeconds:", timeLimitInSeconds);
        console.log("useEffect - timeElapsed:", timeElapsed);
        console.log(
          "useEffect - adjustedCountdown:",
          Math.round(adjustedCountdown)
        );

        setCountdown(Math.round(adjustedCountdown));
      } else {
        setCountdown(timeLimitInSeconds);
      }
    }
  }, [isRecording, timeLimit, lastImageTime]);

  useEffect(() => {
    if (projectId && userId) {
      const projectRef = doc(db, "projects", projectId);

      const unsubscribe = onSnapshot(projectRef, (doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(":::data", data);
          if (data.userId === userId) {
            if (isRecording !== (data.status === "capturing")) {
              setIsRecording(data.status === "capturing");
            }
            setOverlayStatus(data.overlayStatus || false);
            setFlashOn(data.flashOn || false); // Fetch flash status
            setCameraType(data.cameraType || CameraType.back); // Fetch camera type
            setTimeLimit(convertSecondsToTimeLimit(data.timeLimit || 60 * 60)); // Fetch time limit
            setLastImageTime(data.lastImageTime || null); // Fetch time of last image
            setZoom(data.zoom || 0); // Fetch zoom level
            if (data.status === "capturing") {
              setCountdown(data.timeLimit || 60 * 60); // Initialize countdown with time limit from database
            }
            // Update scheduledStartTime
            if (data.scheduledStartTime) {
              setScheduledStartTime(data.scheduledStartTime.toDate());
            } else {
              const now = new Date();
              setScheduledStartTime(now);
              updateProjectDoc({
                scheduledStartTime: Timestamp.fromDate(now),
              });
            }
          }
        }
      });

      // Clean up the listener when the component is unmounted or when projectId/userId changes.
      return () => unsubscribe();
    }
  }, [isRecording, projectId, userId]);

  const updateRecordingStatus = async (isRecordingNewValue: boolean) => {
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, {
      status: isRecordingNewValue ? "capturing" : "idle",
    });
  };

  const takePhoto = useCallback(async () => {
    updateBatteryLevel({ projectId });
    if (!isCameraReady || cameraRef.current === null) {
      console.log("Camera is not ready");
      return;
    }
    const fileCount = await fetchFileCount();

    // Check if the maximum number of images has been reached
    if (fileCount >= 10000) {
      alert("Maximum number of images reached.");
      return;
    }

    const imageIndex = fileCount;
    try {
      const photo = await cameraRef.current.takePictureAsync({
        quality: 1,
        base64: true,
      });

      const paddedNumber = pad(imageIndex, 8, 0);
      const newFilename = `${paddedNumber}.jpg`;

      const blob = await getBlobFroUri(photo.uri);

      const reference = refStorage(storage, `${folderPath}/` + newFilename);

      if (!blob || !reference) {
        return console.log(":::Blob is null");
      }

      await uploadBytesResumable(reference, blob, {
        contentType: "image/jpeg",
      });

      console.log(":::UploadedTo:", `${folderPath}/` + newFilename);

      const currentTime = Date.now();
      setLastImageTime(currentTime);

      await updateProjectDoc({
        lastImageTime: currentTime,
      });
    } catch (error) {
      console.log("upload error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchFileCount,
    folderPath,
    isCameraReady,
    updateProjectDoc,
    cameraRef.current,
  ]);

  useEffect(() => {
    const currentTime = Date.now();
    const timeLimitInSeconds = convertTimeLimitToSeconds(timeLimit);
    if (
      isRecording && // Ensure the project is active
      (!lastImageTime ||
        lastImageTime < currentTime - timeLimitInSeconds * 1000)
    ) {
      takePhoto();
    }
  }, [isRecording, lastImageTime, takePhoto, timeLimit]);

  const handleFlashClick = async () => {
    // Update flash status in Firestore
    const newFlashStatus = !flashOn;
    setFlashOn(newFlashStatus);

    await updateProjectDoc({
      flashOn: newFlashStatus, // Update flash status
    });
  };

  const handleOverlayClick = async () => {
    const newOverlayStatus = !overlayStatus;
    setOverlayStatus(newOverlayStatus);

    await updateProjectDoc({
      overlayStatus: newOverlayStatus,
    });
  };

  const handleCameraFlip = async () => {
    const newCameraType =
      cameraType === CameraType.back ? CameraType.front : CameraType.back;
    setCameraType(newCameraType);

    await updateProjectDoc({
      cameraType: newCameraType, // Update camera type
    });
  };

  const handleZoomClick = () => {
    const zoomValues = Object.keys(zoomLevels).map(Number);
    const currentIndex = zoomValues.indexOf(zoom);
    const newIndex = (currentIndex + 1) % zoomValues.length;
    const newZoom = zoomValues[newIndex];
    setZoom(newZoom);
    updateProjectDoc({
      zoom: newZoom, // Update zoom level
    });
  };

  useEffect(() => {
    const setDefaultValues = async () => {
      if (projectId && userId) {
        const projectRef = doc(db, "projects", projectId);
        const docSnap = await getDoc(projectRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Check if overlayStatus exists, if not set default value
          if (!("overlayStatus" in data)) {
            await updateDoc(projectRef, {
              overlayStatus: false,
            });
          }

          // Check if flashOn exists, if not set default value
          if (!("flashOn" in data)) {
            await updateDoc(projectRef, {
              flashOn: false,
            });
          }

          // Check if cameraType exists, if not set default value
          if (!("cameraType" in data)) {
            await updateDoc(projectRef, {
              cameraType: CameraType.back,
            });
          }

          // Check if timeLimit exists, if not set default value
          if (!("timeLimit" in data)) {
            await updateDoc(projectRef, {
              timeLimit: 60 * 60, // Default to 1 hour
            });
          }

          // Check if zoom exists, if not set default value
          if (!("zoom" in data)) {
            await updateDoc(projectRef, {
              zoom: 0, // Default to 0
            });
          }
        }
      }
    };

    setDefaultValues();
  }, [projectId, userId]); // Empty dependency array to run only once at start

  useEffect(() => {
    if (isRecording && lastImageTime === null) {
      const currentTime = Date.now();
      setLastImageTime(currentTime);

      updateProjectDoc({
        lastImageTime: currentTime,
      });
    }
  }, [isRecording, lastImageTime, projectId, updateProjectDoc, userId]);

  const shouldTakePhoto = useCallback(() => {
    if (!isRecording || !scheduledStartTime) return false;

    const now = new Date();
    const timeLimitInSeconds = convertTimeLimitToSeconds(timeLimit);
    const intervalMs = timeLimitInSeconds * 1000;

    // Calculate the time elapsed since the scheduled start
    const elapsedMs = now.getTime() - scheduledStartTime.getTime();

    // Calculate the number of full intervals that have passed
    const intervals = Math.floor(elapsedMs / intervalMs);

    // Calculate the next scheduled time for a photo
    const nextScheduledTime = new Date(
      scheduledStartTime.getTime() + intervals * intervalMs
    );

    // Check if we're within 1 second of the next scheduled time
    return (
      now.getTime() >= nextScheduledTime.getTime() &&
      now.getTime() < nextScheduledTime.getTime() + 1000
    );
  }, [isRecording, scheduledStartTime, timeLimit]);

  const updateCountdown = useCallback(() => {
    if (!scheduledStartTime) return;

    const now = new Date();
    const timeLimitInSeconds = convertTimeLimitToSeconds(timeLimit);
    const intervalMs = timeLimitInSeconds * 1000;

    // Calculate the time elapsed since the scheduled start
    const elapsedMs = now.getTime() - scheduledStartTime.getTime();

    // Calculate the number of full intervals that have passed
    const intervals = Math.floor(elapsedMs / intervalMs);

    // Calculate the next scheduled time for a photo
    const nextScheduledTime = new Date(
      scheduledStartTime.getTime() + (intervals + 1) * intervalMs
    );

    // Update the countdown
    const timeUntilNextPhoto = nextScheduledTime.getTime() - now.getTime();
    if (timeUntilNextPhoto > 0) {
      setCountdown(Math.floor(timeUntilNextPhoto / 1000));
    }
  }, [scheduledStartTime, timeLimit]);

  if (!permission) {
    return <View />;
  }
  if (!permission.granted) {
    return <Text style={{ color: "white" }}>No access to camera</Text>;
  }

  const IconButton = ({ onPress, iconName, text, color = "white" }) => (
    <TouchableOpacity
      style={styles.iconGroup}
      onPress={onPress}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
    >
      <Ionicons name={iconName} size={24} color={color} />
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );

  if (!projectId) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={styles.container}>
      <ReactInterval
        timeout={1000}
        enabled={isRecording}
        callback={() => {
          if (shouldTakePhoto()) {
            takePhoto();
          } else {
            updateCountdown();
          }
        }}
      />
      <View style={styles.cameraContainer}>
        <Camera
          flashMode={flashOn ? FlashMode.on : FlashMode.off}
          style={styles.camera}
          type={cameraType}
          zoom={zoom} // Set zoom value
          ref={cameraRef}
          onCameraReady={() => {
            console.log("Camera is ready");
            setIsCameraReady(true);
          }}
        ></Camera>
      </View>
      <View
        style={[
          styles.bottomBar,
          {
            height: bottomBarHeight,
          },
        ]}
      >
        <IconButton
          onPress={() => {
            removeData("projectId");
            storeData("mode", "remote");
            navigation.reset({
              index: 0,
              routes: [{ name: "ProjectsList" }],
            });
          }}
          iconName="arrow-back"
          text="Back"
        />
        <View style={styles.barCenter}>
          <IconButton
            onPress={() => {}}
            iconName="images"
            text={formatLastImageTime(lastImageTime)}
          />
          {isRecording ? (
            <CountdownTimer countdown={countdown} />
          ) : (
            <TimeLimit
              timeLimit={timeLimit}
              onPress={isRecording ? null : handleTimeLimitClick}
              // onLongPress={handleTimeLimitLongPress}
            />
          )}
          <RecordButton
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            updateRecordingStatus={updateRecordingStatus}
          />
          <IconButton
            onPress={handleFlashClick}
            iconName={flashOn ? "flash" : "flash-off"}
            text={flashOn ? "Flash On" : "Flash Off"}
          />
          {/* <IconButton
            onPress={handleOverlayClick}
            iconName={overlayStatus ? "layers" : "layers-outline"}
            color={overlayStatus ? "white" : "grey"}
            text={overlayStatus ? "Overlay On" : "Overlay Off"}
          /> */}
          <IconButton
            onPress={handleZoomClick}
            iconName="search"
            text={zoomLevels[zoom]}
          />
        </View>
        <IconButton
          onPress={handleCameraFlip}
          iconName="camera-reverse-outline"
          text="Flip"
        />
      </View>
      <ScheduleModal
        isVisible={isScheduleModalVisible}
        onClose={() => setIsScheduleModalVisible(false)}
        onSchedule={handleSchedule}
        projectId={projectId}
        userId={userId}
      />
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cameraContainer: {
    backgroundColor: "black",
    alignItems: "center",
  },
  camera: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  bottomBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    height: 80,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
    gap: 30,
  },
  barCenter: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: 30,
  },
  iconGroup: {
    alignItems: "center",
  },
  iconGroupHorizontal: {
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    color: "white",
  },
});

export default ProjectCamera;
