import React from "react";
import { View } from "react-native";

import Colors from "./Colors";
import { ProjectStates } from "../config/projectStates";

const HealthCircle = ({ status }: { status: ProjectStates }) => {
  let color;

  switch (status) {
    case ProjectStates.capturing:
      color = Colors.accent1;
      break;
    case ProjectStates.paused:
      color = Colors.accent3;
      break;
    case ProjectStates.error:
      color = Colors.accent2;
      break;
    default:
      color = Colors.accent1;
  }

  return (
    <View
      style={{
        width: 15,
        height: 15,
        borderRadius: 15 / 2,
        backgroundColor: color,
      }}
    />
  );
};

export default HealthCircle;
