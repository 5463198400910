import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity, View, Text, StyleSheet, Image } from "react-native";
import Type, { TypeVariant } from "./Type";
import { formatLastImageTime } from "./ProjectCamera/utils/formatLastImageTime";
import { Ionicons } from "@expo/vector-icons";
import HealthCircle from "./HealthCircle";
import { ProjectStates } from "../config/projectStates";

const ProjectCard = ({
  id,
  name,
  thumbnail,
  battery,
  health,
  remainingTime,
  numImages,
  lastImage,
  onClick = () => {},
  isActive = false,
}) => {
  const navigation = useNavigation();
  const onPress = () => {
    navigation.navigate("Project", {
      id,
    });
    onClick();
  };

  let lastImageText = lastImage && formatLastImageTime(lastImage);

  if (health === ProjectStates.paused) {
    lastImageText = "stopped - " + lastImageText;
  } else if (health === ProjectStates.error) {
    lastImageText = "error - " + lastImageText;
  }

  return (
    <TouchableOpacity
      style={[styles.projectContainer, isActive ? styles.activeBorder : {}]}
      onPress={onPress}
    >
      <View style={styles.thumbnailContainer}>
        <Image source={thumbnail} style={styles.thumbnail} />
      </View>
      <View style={styles.projectInfo}>
        <View style={styles.nameContainer}>
          <Type variant={TypeVariant.SmallTitle}>{name}</Type>
        </View>
        <View style={styles.statsContainer}>
          <View style={styles.statItem}>
            {/* <Ionicons name="time" size={16} color="black" /> */}
            <HealthCircle status={health} />
            <Text style={styles.statText}>{lastImageText}</Text>
          </View>
          <View style={styles.statItem}>
            {health !== ProjectStates.paused ? (
              <>
                <Ionicons name="battery-charging" size={16} color="black" />
                <Text style={styles.statText}>
                  {battery ? battery + "%" : "-"}
                </Text>
              </>
            ) : (
              <Text>&nbsp;</Text>
            )}
          </View>
          <View style={styles.statItem}>
            <Ionicons name="images" size={16} color="black" />
            <Text style={styles.statText}>{numImages}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  thumbnailContainer: {
    width: "30%",
  },
  projectInfo: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: 10,
  },
  thumbnail: {
    width: "100%",
    borderRadius: 10,
    height: undefined,
    aspectRatio: 1,
  },
  statText: {
    color: "#808080",
  },
  activeBorder: {
    borderColor: "green",
    borderWidth: 2,
  },
  name: {
    fontSize: 20,
    fontWeight: "bold",
  },
  projectContainer: {
    flexDirection: "row",
    padding: 16,
    borderColor: "#d3d3d3",
    borderRadius: 10,
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: 800,
    alignSelf: "center",
  },
  nameContainer: {},
  statsContainer: {
    gap: 10,
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  statItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
});

export default ProjectCard;
