import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { ResizeMode, Video } from "expo-av";
import { logEvent } from "firebase/analytics";
import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Platform,
  Animated,
  TouchableOpacity,
  Linking,
} from "react-native";

import { analytics } from "../../firebaseConfig";
import Button, { ButtonVariant } from "../components/Button";
import Colors from "../components/Colors";

const screenHeight = Dimensions.get("window").height;

const LandingScreen = () => {
  const videoRef = useRef(null);
  const navigation = useNavigation();

  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
  }, []);

  const onGetStarted = () => {
    logEvent(analytics, "login", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
    navigation.navigate("Login");
  };

  const onQuickTutorial = () => {
    logEvent(analytics, "quick_tutorial", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
    navigation.navigate("Onboard");
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const [showStoreButtons, setShowStoreButtons] = useState(false);
  const storeButtonsAnimation = useRef(new Animated.Value(0)).current;

  const onDownloadApp = () => {
    logEvent(analytics, "download_app", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
    setShowStoreButtons(true);
    Animated.spring(storeButtonsAnimation, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  };

  const openAppStore = () => {
    logEvent(analytics, "open_app_store", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
    Linking.openURL(
      "https://apps.apple.com/us/app/ptl-camera-plant-timelapse/id6476889599"
    );
  };

  const openPlayStore = () => {
    logEvent(analytics, "open_play_store", {
      firebase_screen: "Home",
      firebase_screen_class: "HomeScreen",
    });
    Linking.openURL(
      "https://play.google.com/store/apps/details?id=com.arrygoo.ptlcamera"
    );
  };

  return (
    <View style={styles.container}>
      {Platform.OS === "web" ? (
        <video
          ref={videoRef}
          style={styles.video}
          src={require("../../assets/heroVideo.mp4")}
          loop
          autoPlay
          playsInline
          muted
        />
      ) : (
        <Video
          ref={videoRef}
          style={styles.backgroundVideo}
          source={require("./heroVideo.mp4")}
          shouldPlay={videoLoaded}
          isLooping
          isMuted
          resizeMode={ResizeMode.COVER}
          onLoad={handleVideoLoad}
        />
      )}
      <View style={styles.backDrop}>
        <Text style={styles.heroTitle}>ptl.camera</Text>
        <Text style={styles.heroSubtitle}>
          Capture beautiful plant timelapse videos
        </Text>
        <Button
          title="Quick Tutorial"
          variant={ButtonVariant.Secondary}
          onPress={onQuickTutorial}
          textStyle={{ color: Colors.black }}
          style={{
            minWidth: 200,
            alignItems: "center",
          }}
        />

        <Button
          title="Login"
          variant={ButtonVariant.Tertiary}
          onPress={onGetStarted}
          textStyle={{ color: Colors.white }}
          style={{
            borderColor: Colors.white,
            borderWidth: 1,
            minWidth: 200,
            alignItems: "center",
          }}
        />
        {Platform.OS === "web" && (
          <>
            <Button
              title="Download App"
              variant={ButtonVariant.Tertiary}
              onPress={onDownloadApp}
              textStyle={{ color: Colors.white }}
              style={{
                borderColor: Colors.white,
                borderWidth: 1,
                minWidth: 200,
                alignItems: "center",
              }}
            />
            {showStoreButtons && (
              <Animated.View
                style={[
                  styles.storeButtonsContainer,
                  {
                    opacity: storeButtonsAnimation,
                    transform: [{ scale: storeButtonsAnimation }],
                  },
                ]}
              >
                <TouchableOpacity
                  style={styles.storeButton}
                  onPress={openAppStore}
                >
                  <Ionicons name="logo-apple" size={24} color="white" />
                  <Text style={styles.storeButtonText}>App Store</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.storeButton}
                  onPress={openPlayStore}
                >
                  <Ionicons
                    name="logo-google-playstore"
                    size={24}
                    color="white"
                  />
                  <Text style={styles.storeButtonText}>Play Store</Text>
                </TouchableOpacity>
              </Animated.View>
            )}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: Colors.black,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  backDrop: {
    flex: 1,
    gap: 20,
    backgroundColor: "rgba(0,0,0,0.7)",
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: screenHeight / 3,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  heroTitle: {
    fontSize: 48,
    fontWeight: "bold",
    color: "white",
    fontFamily: "MilaLight",
  },
  heroSubtitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    fontFamily: "Roboto_100Thin",
    textAlign: "center",
  },
  video: {
    position: "absolute",
    bottom: -200,
    flex: 1,
    width: "100%",
    opacity: 0.5,
  },
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: -200,
    right: 0,
    width: "100%",
    opacity: 0.5,
  },
  storeButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 100,
  },
  storeButton: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
  },
  storeButtonText: {
    color: "white",
    marginLeft: 10,
  },
});

export default LandingScreen;
