// Alert.web.ts
import { AlertButton, AlertStatic } from "react-native";

class WebAlert implements Pick<AlertStatic, "alert"> {
  public alert(title: string, message?: string, buttons?: AlertButton[]): void {
    // If no buttons are provided, use window.alert and return
    if (!buttons?.length) {
      window.alert([title, message].filter(Boolean).join("\n"));
      return;
    }

    const result = window.confirm([title, message].filter(Boolean).join("\n"));

    const button = buttons.find(({ style }) =>
      result ? style !== "cancel" : style === "cancel"
    );

    button?.onPress?.();
  }
}

export const Alert = new WebAlert();
