import React, { useState, useEffect, useRef } from "react";
import {
  View,
  ActivityIndicator,
  StyleSheet,
  Button,
  Alert,
} from "react-native";
import { Asset } from "expo-asset";
import * as FileSystem from "expo-file-system";
import { Video, ResizeMode } from "expo-av";
import * as Sharing from "expo-sharing";
import Type from "./Type";
import { formatLastImageTime } from "./ProjectCamera/utils/formatLastImageTime";
import Colors from "./Colors";

const VideoContainer = ({ videoPath }) => {
  const video = useRef(null);
  const [status, setStatus] = useState({});
  const [localUri, setLocalUri] = useState(null);
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const downloadVideo = async () => {
      const { localUri } = await Asset.fromURI(videoPath).downloadAsync();
      setLocalUri(localUri);
      setVideoLoading(false);
    };

    downloadVideo();
  }, [videoPath]);

  const handleShare = async () => {
    if (!(await Sharing.isAvailableAsync())) {
      alert("Uh oh, sharing isn't available on your platform");
      return;
    }

    // Download the video to the local file system
    const downloadResumable = FileSystem.createDownloadResumable(
      videoPath,
      FileSystem.documentDirectory + "myVideo.mp4"
    );

    try {
      const { uri } = await downloadResumable.downloadAsync();
      // Share the local file URI
      Sharing.shareAsync(uri);
    } catch (e) {
      console.error(e);
      Alert.alert("Download failed", e.message);
    }
  };

  return (
    <>
      {/* // <View style={styles.videoContainer}> */}
      {videoLoading && <ActivityIndicator size="large" color="#0000ff" />}
      {!videoLoading && (
        <>
          {/* <View style={{ flex: 1, alignItems: "center" }}> */}
          <Video
            ref={video}
            style={styles.video}
            source={{ uri: localUri }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping
            onPlaybackStatusUpdate={(status) => setStatus(() => status)}
            videoStyle={{
              width: "100%",
              height: "100%",
            }}
          />
          {/* </View> */}
          {/* <View style={styles.buttons}>
            <Button
              title={status.isPlaying ? "Pause" : "Play"}
              onPress={() =>
                status.isPlaying
                  ? video.current.pauseAsync()
                  : video.current.playAsync()
              }
            />

             <Button title="Share" onPress={handleShare} /> 
          </View> */}
        </>
      )}
      {/* </View> */}
    </>
  );
};

export default VideoContainer;

const styles = StyleSheet.create({
  videoContainer: {
    flex: 0,
    // make it shrink to fit the content
  },
  video: {
    width: "100%",
    height: 300,
  },
  buttons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    // marginTop: 10,
  },
  videoTimestamp: {
    position: "absolute",
    bottom: 25,
    left: 25,
    color: "white",
    fontSize: 16,
    textShadowColor: "rgba(0, 0, 0, 0.9)", // Increase the opacity of the shadow color
    textShadowOffset: { width: -2, height: 2 }, // Increase the offset to make the shadow larger
    textShadowRadius: 15, // Increase the radius to make the shadow softer
  },
});
