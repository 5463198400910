import Placeholder1 from "../../assets/placeholders/placeholder1.png";
import Placeholder2 from "../../assets/placeholders/placeholder2.png";
import Placeholder3 from "../../assets/placeholders/placeholder3.png";
import Placeholder4 from "../../assets/placeholders/placeholder4.png";
import Placeholder5 from "../../assets/placeholders/placeholder5.png";
import Placeholder6 from "../../assets/placeholders/placeholder6.png";
import Placeholder7 from "../../assets/placeholders/placeholder7.png";
import Placeholder8 from "../../assets/placeholders/placeholder8.png";

const getPlaceholderImageForId = (id: string) => {
  const placeholderImages = [
    Placeholder2,
    Placeholder1,
    Placeholder3,
    Placeholder4,
    Placeholder5,
    Placeholder6,
    Placeholder7,
    Placeholder8,
  ];
  const index = id.charCodeAt(0) % placeholderImages.length;
  return placeholderImages[index];
};

export default getPlaceholderImageForId;
