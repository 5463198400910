import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { Alert } from "react-native";

export const handleShare = async (videoUrl: string) => {
  console.log("videoUrl", videoUrl);
  if (!(await Sharing.isAvailableAsync())) {
    Alert.alert(
      "Sharing Unavailable",
      "Sharing isn't available on your platform"
    );
    return;
  }

  try {
    const fileUri = FileSystem.documentDirectory + "sharedVideo.mp4";
    const downloadResumable = FileSystem.createDownloadResumable(
      videoUrl,
      fileUri
    );

    const { uri } = await downloadResumable.downloadAsync();
    console.log("Finished downloading to ", uri);

    await Sharing.shareAsync(uri);
  } catch (error) {
    console.error("Error sharing video:", error);
    Alert.alert(
      "Sharing Failed",
      "An error occurred while trying to share the video."
    );
  }
};
