// Import the functions you need from the SDKs you need
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAuth, getAuth } from "firebase/auth";
import { getReactNativePersistence } from "firebase/auth/react-native";
// import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUnQq4l4ig5NLSK1sS4IPef9KwW9bK4IU",
  authDomain: "ptlcamera.firebaseapp.com",
  projectId: "ptlcamera",
  storageBucket: "ptlcamera.appspot.com",
  messagingSenderId: "683100808285",
  appId: "1:683100808285:web:a8272b46de562414c4d9b4",
  measurementId: "G-XRTRK476MW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});

export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
