import Ionicons from "@expo/vector-icons/Ionicons";
import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

export const TimeLimit = ({ timeLimit, onPress, onLongPress }) => (
  <TouchableOpacity
    style={styles.iconGroup}
    onPress={onPress}
    onLongPress={onLongPress}
    hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
  >
    <Ionicons name="time" size={24} color="white" />
    <Text style={styles.text}>{timeLimit}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  iconGroup: {
    alignItems: "center",
  },

  text: {
    color: "white",
  },
});
