import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  Image,
  StyleSheet,
  Switch,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import CameraSummary from "./CameraSummary";
import PhotoGallery from "./PhotoGallery";
import PhotoGallerySummary from "./PhotoGallerySummary";
import VideoGallerySummary from "./VideosGallerySummary";
import CameraIconBw from "../../assets/adaptive-icon-w.png";
import { db } from "../../firebaseConfig";
import FloatingButton from "../components/FloatingButton";
import Type, { TypeVariant } from "../components/Type";
import Colors from "../components/Colors";
import VideoTab from "../components/VideoTab";
import { storeData } from "../utils/storage";

const auth = getAuth();

const CameraTab = ({ userId, id }) => (
  <View style={{ flex: 1 }}>
    <CameraSummary projectId={id} userId={userId} />
  </View>
);

const PhotoTab = ({ userId, id }) => (
  <View style={{ flex: 1 }}>
    <PhotoGallery
      projectId={id}
      userId={userId}
      directory="images"
      title="Photos"
    />
  </View>
);

const renderTabBar = (props) => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: Colors.primary }}
    style={{ backgroundColor: "white", color: "red" }}
    renderLabel={({ route, focused, color }) => (
      <Type
        variant={TypeVariant.SmallTitle}
        style={{ color: focused ? Colors.black : Colors.accent3 }}
      >
        {route.title}
      </Type>
    )}
  />
);

const ProjectDetails = ({ id }) => {
  const [projectData, setProjectData] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const navigation = useNavigation();
  const userId = auth.currentUser.uid;

  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "camera", title: "Camera" },
    { key: "video", title: "Video" },
    { key: "photo", title: "Photo" },
  ]);

  useEffect(() => {
    const fetchProject = async () => {
      const docRef = doc(db, "projects", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProjectData(docSnap.data());
        setIsCapturing(docSnap.data().status === "capturing");
      } else {
        console.log("No such document!");
      }
    };

    fetchProject();
  }, [id]);

  const toggleSwitch = async () => {
    setIsCapturing((previousState) => !previousState);

    const newStatus = isCapturing ? "paused" : "capturing";
    await updateDoc(doc(db, "projects", id), {
      status: newStatus,
      userId,
    });
  };

  if (!projectData) {
    return <Text>Loading...</Text>;
  }

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "video":
        return <VideoTab userId={userId} id={id} />;
      case "camera":
        return <CameraTab userId={userId} id={id} />;
      case "photo":
        return <PhotoTab userId={userId} id={id} />;
      default:
        return null;
    }
  };

  const handleSwitchToCamera = () => {
    storeData("mode", "camera");
    storeData("projectId", id);
    navigation.navigate("Camera", { id });
  };

  const { name, battery, health, remainingTime, numImages } = projectData;

  return (
    <View style={styles.container}>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={renderTabBar}
        animationEnabled={false}
        lazy={true} // Add this line
      />

      {index === 0 && (
        <FloatingButton onPress={handleSwitchToCamera}>
          <Image
            source={CameraIconBw}
            style={{
              width: 30,
              height: 30,
              position: "relative",
            }}
          />
        </FloatingButton>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  projectInfo: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  statsText: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

export default ProjectDetails;
