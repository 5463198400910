import { View, Text, StyleSheet, Image, Switch } from "react-native";
import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { getAuth } from "firebase/auth";
import { getData } from "../utils/storage";
import ProjectDetails from "./ProjectDetails";
import ProjectCamera from "../components/ProjectCamera/ProjectCamera";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

const auth = getAuth();

const Project = ({ route }) => {
  const userId = auth.currentUser.uid;
  const { id } = route.params;
  const [projectData, setProjectData] = useState(null);
  const [projectName, setProjectName] = useState(""); // New state variable for project name
  const [isCapturing, setIsCapturing] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [mode, setMode] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    const fetchMode = async () => {
      const storedMode = await getData("mode");
      setMode(storedMode);
    };

    fetchMode();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const docRef = doc(db, "projects", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setProjectData(data);
        setProjectName(data.name); // Set the project name
        setIsCapturing(data.status === "capturing");
        setBatteryLevel(Number(data.batteryLevel).toFixed(2));

        // Update the navigation options with the project name
        navigation.setOptions({
          title: data.name,
        });
      } else {
        console.log("No such document!");
      }
    };

    fetchProject();
  }, [id, navigation]);

  // const toggleSwitch = async () => {
  //   setIsCapturing((previousState) => !previousState);
  //   const newStatus = isCapturing ? "paused" : "capturing";
  //   await updateDoc(doc(db, "projects", id), {
  //     status: newStatus,
  //     userId,
  //   });
  // };

  if (!projectData) {
    return <Text>Loading...</Text>;
  }

  if (mode === "camera") {
    navigation.navigate("Camera", { id });
  }

  return (
    <View style={styles.container}>
      {/* <Text style={styles.statsText}>
          Status: {isCapturing ? "Capturing" : "Paused"}
        </Text>
        <Text style={styles.statsText}>Battery: {batteryLevel}%</Text> */}
      {mode === "camera" ? (
        // <ProjectCamera id={id} />
        <Text>Loading Camera..</Text>
      ) : (
        <ProjectDetails id={id} />
      )}
      {/* <Switch
          ios_backgroundColor="#3e3e3e"
          onValueChange={toggleSwitch}
          value={isCapturing}
        /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: "#fff",
    // padding: 20,
  },
  projectInfo: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  statsText: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

export default Project;
