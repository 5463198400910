import { Dimensions, TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

const SettingsButton = () => {
  const navigation = useNavigation();
  const screenWidth = Dimensions.get("window").width;
  const marginRight = screenWidth > 880 ? 40 : 30;

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("Profile")}
      style={{
        marginRight,
      }}
    >
      <Feather name="settings" size={24} color="black" />
    </TouchableOpacity>
  );
};

export default SettingsButton;
