import {
  View,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Text,
  Modal,
  TouchableOpacity,
} from "react-native";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";

import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import React, { useState, useEffect, useCallback } from "react";
import {
  doc,
  onSnapshot,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getCountFromServer, // Import the count function
} from "firebase/firestore";
import { useActionSheet } from "@expo/react-native-action-sheet"; // Import the hook

import Button, { ButtonVariant } from "../components/Button";
import VideoContainer from "../components/VideoPlayer";
import { db } from "../../firebaseConfig";
import Type from "./Type";
import { formatLastImageTime } from "./ProjectCamera/utils/formatLastImageTime";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import Colors from "./Colors";
import FloatingButton from "../components/FloatingButton";
import GenerateVideo from "./GenerateVideo";
import { VideoThumbnailList } from "./VideoThumbnailList";
import { Alert } from "./Alert";
import { Linking } from "expo";
import { statusMap } from "../utils/statusMap";
import { BASE_URL } from "../config/constants";

const VIDEO_LIMIT = 20; // Capitalized constant variable for the limit

const VideoTab = ({ userId, id }) => {
  const [videos, setVideos] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoGenerationProgress, setVideoGenerationProgress] = useState("");
  const [videoCounter, setVideoCounter] = useState(VIDEO_LIMIT);
  const [videoStatus, setVideoStatus] = useState(null); // ["loading", "ready"
  const [lastGeneratedDate, setLastGeneratedDate] = useState(null);
  const [videoCreationTime, setVideoCreationTime] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [videoGeneration, setVideoGeneration] = useState(null); // New state for videoGeneration field
  const [framesCount, setFramesCount] = useState(0);
  const [totalImagesCount, setTotalImagesCount] = useState(0); // Default value, will update from Firestore
  const [isLoading, setIsLoading] = useState(false); // Define isLoading as a state
  const [isPreviewVideo, setIsPreviewVideo] = useState(false);
  const [mappedVideoStatus, setMappedVideoStatus] = useState(""); // New state for mapped video status
  const [isModalVisible, setIsModalVisible] = useState(false); // Add state for modal visibility

  const projectRef = doc(db, "projects", id);

  const checkAndUpdateVideoLimit = async () => {
    const docSnap = await getDoc(projectRef);
    const today = new Date().toDateString();

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (!data.videoLimits || data.videoLimits.lastGeneratedDate !== today) {
        // It's a new day or no videoLimits set, reset the counter
        await updateDoc(projectRef, {
          "videoLimits.count": VIDEO_LIMIT,
          "videoLimits.lastGeneratedDate": today,
        });
        setVideoCounter(VIDEO_LIMIT);
      } else {
        // Same day, use the existing counter
        setVideoCounter(data.videoLimits.count);
      }
      setLastGeneratedDate(data.videoLimits.lastGeneratedDate);
    } else {
      // No document exists, create one with the initial count and today's date
      await setDoc(projectRef, {
        videoLimits: {
          count: VIDEO_LIMIT,
          lastGeneratedDate: today,
        },
      });
      setVideoCounter(VIDEO_LIMIT);
      setLastGeneratedDate(today);
    }
  };

  const fetchVideos = useCallback(() => {
    const videosQuery = query(
      collection(db, "videos"),
      where("userId", "==", userId),
      where("projectId", "==", id),
      where("isPreview", "==", true)
    );

    const unsubscribe = onSnapshot(videosQuery, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const videoDoc = querySnapshot.docs[0];
        if (!videoDoc) {
          console.log("No video document found");
          return;
        }
        const videoData = videoDoc.data();
        const videoPath = videoData.path; // Get the path from the document
        const storage = getStorage();
        const videoRef = ref(storage, videoPath);
        // Set videoId to the id of the videoDoc
        setVideoId(videoDoc.id);
        if (!videoData.path) {
          setVideos(null);
          setVideoCreationTime(null);
          return;
        }

        getDownloadURL(videoRef).then((videoURL) => {
          const creationTime = videoData.generation; // Extract creation time from videoGeneration field
          setVideoCreationTime(creationTime / 1000); // Set the creation time in state
          setVideos(videoURL);
          if (
            creationTime &&
            videoCreationTime &&
            creationTime !== videoCreationTime
          ) {
            setIsLoading(false);
          }

          // Map the status

          const status = videoData.status;
          setMappedVideoStatus(statusMap[status]);

          if (status === "uploaded" && videoData.progress === 100) {
            setMappedVideoStatus("Starting...");
          } else if (status !== "completed") {
            setVideoGenerationProgress(videoData.progress.toString());
          } else if (status === "completed") {
            setVideoGenerationProgress(videoData.mergingState.toString());
          }

          setIsLoading(status !== "uploaded"); // Set isLoading to true if status exists and is not "uploaded"
        });
      } else {
        setVideos(null);
        setVideoCreationTime(null);
      }
    });

    return unsubscribe; // Return the unsubscribe function to stop listening when needed
  }, [userId, id, videoCreationTime]);

  const fetchImageCount = async () => {
    const imagesQuery = query(
      collection(db, "images"),
      where("userId", "==", userId),
      where("projectId", "==", id)
    );

    const snapshot = await getCountFromServer(imagesQuery);
    return snapshot.data().count;
  };

  const generatePaddedNumber = async () => {
    const imageCount = await fetchImageCount();
    const paddedNumber = String(imageCount).padStart(5, "0"); // Adjust padding as needed
    return paddedNumber;
  };

  const generateVideo = async () => {
    setIsLoading(true);
    await updateDoc(projectRef, {
      "videoLimits.count": videoCounter - 1,
    });
    setVideoCounter(videoCounter - 1);

    const paddedNumber = await generatePaddedNumber(); // Use the padded number

    const url = `${BASE_URL}/generateProjectVideoBatch?projectId=${id}&userId=${userId}&isPreview=true&quality=hd&imageIndex=${paddedNumber}`;

    const response = await fetch(url);
    const data = await response.json();
    const videoId = data?.videoId;
    if (videoId) {
      setVideoId(videoId);
    }
    console.log("Response:", data);
  };

  useEffect(() => {
    const unsubscribe = fetchVideos();
    return () => unsubscribe();
  }, [fetchVideos]);

  // Fetch frames count
  useEffect(() => {
    const fetchFramesCount = async () => {
      const videosQuery = query(
        collection(db, "videos"),
        where("projectId", "==", id),
        where("userId", "==", userId),
        where("isPreview", "==", true)
      );
      const querySnapshot = await getDocs(videosQuery);
      const totalFrames = querySnapshot.docs.reduce(
        (total, doc) => total + doc.data().processedFrames,
        0
      );
      console.log("Total frames:", totalFrames);
      setFramesCount(
        typeof totalFrames === "number" && !isNaN(totalFrames) ? totalFrames : 0
      );
    };

    const fetchImagesCount = async () => {
      const projectDoc = await getDoc(doc(db, "projects", id));
      if (projectDoc.exists()) {
        const projectData = projectDoc.data();
        const totalImages = projectData.imagesCount || 0;
        setTotalImagesCount(totalImages);
      }
    };

    fetchFramesCount();
    fetchImagesCount();
  }, [userId, id]);

  const readableVideoCreationTime = formatLastImageTime(videoCreationTime);

  const refetchData = () => {
    fetchVideos();
    checkAndUpdateVideoLimit();
  };

  const { showActionSheetWithOptions } = useActionSheet(); // Use the hook

  const handleShare = async () => {
    if (!(await Sharing.isAvailableAsync())) {
      alert("Uh oh, sharing isn't available on your platform");
      return;
    }

    // Download the video to the local file system
    const downloadResumable = FileSystem.createDownloadResumable(
      videos,
      FileSystem.documentDirectory + "myVideo.mp4"
    );

    try {
      const { uri } = await downloadResumable.downloadAsync();
      console.log("Finished downloading to ", uri);
      // Share the local file URI
      Sharing.shareAsync(uri);
    } catch (e) {
      console.error(e);
      Alert.alert("Download failed", e.message);
    }
  };

  const hamburgerOnPress = () => {
    console.log("hamburgerOnPress called");
    const options = ["Video Settings", "Share", "Issues?", "Cancel"];
    const cancelButtonIndex = 3;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            setIsPreviewVideo(true);
            setIsModalVisible(true);
            break;
          case 1:
            handleShare();
            break;
          case 2:
            handleIssues();
            break;
          case cancelButtonIndex:
            break;
        }
      }
    );
  };

  const handleIssues = () => {
    const issueOptions = [
      "Restart video generation",
      "Email feedback",
      "Cancel",
    ];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options: issueOptions,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            clearCache({ shouldRestartVideo: true });
            break;
          case 1:
            sendFeedbackEmail();
            break;
          case cancelButtonIndex:
            break;
        }
      }
    );
  };

  const clearCache = async ({ shouldRestartVideo = false }) => {
    const url = `${BASE_URL}/clearChunksForVideoRequest?videoId=${videoId}&shouldRestartVideo=${shouldRestartVideo}`;

    try {
      const response = await fetch(url, { method: "POST" });
      const data = await response.json();
      console.log("Cache cleared:", data);
    } catch (e) {
      console.error("Failed to clear cache:", e);
      Alert.alert("Clear cache failed", e.message);
    }
  };

  const sendFeedbackEmail = () => {
    const email = "arrygoo@gmail.com";
    const subject = "issue";
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    Linking.openURL(mailtoUrl).catch((err) =>
      console.error("Failed to send email:", err)
    );
  };

  const onRestartPress = () => {
    Alert.alert(
      "Confirmation",
      "Do you want to restart the video generation process?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => clearCache({ shouldRestartVideo: true }),
        },
      ],
      { cancelable: false }
    );
  };

  // console.log how long it's been since the video was created
  const hasItBeenTenMinutesSinceVideoCreation =
    Date.now() - videoCreationTime > 600000;

  return (
    <ScrollView contentContainerStyle={[styles.scrollView]}>
      {/* Hamburger menu */}
      <TouchableOpacity style={styles.hamburgerMenu} onPress={hamburgerOnPress}>
        <MaterialIcons name="menu" size={36} color={"grey"} />
      </TouchableOpacity>
      {videos ? (
        <VideoContainer key={0} videoPath={videos} />
      ) : (
        <View style={styles.noVideosContainer}>
          <Type style={styles.noVideosText}>There are no videos yet.</Type>
        </View>
      )}

      <View style={[styles.row, styles.previewRow]}>
        {!isLoading && (
          <Type style={styles.previewText}>
            Preview video: {formatLastImageTime(videoCreationTime)}
          </Type>
        )}
        {isLoading && (
          <View style={[styles.row, styles.spaceBetween]}>
            <Type style={styles.previewText}>
              {mappedVideoStatus}...{" "}
              {mappedVideoStatus !== "Uploading" &&
                `${videoGenerationProgress}%`}{" "}
              {"  "}
              {isLoading && (
                <ActivityIndicator size="small" color={Colors.white} />
              )}
            </Type>

            {hasItBeenTenMinutesSinceVideoCreation && (
              <MaterialCommunityIcons
                name="restart-alert"
                size={24}
                color="white"
                style={styles.restartIcon}
                onPress={onRestartPress}
              />
            )}
          </View>
        )}
      </View>
      <View style={styles.row}></View>

      <GenerateVideo
        projectId={id}
        userId={userId}
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          refetchData();
        }}
        onCloseAndRefresh={refetchData}
        isPreviewVideo={isPreviewVideo}
      />
      <VideoThumbnailList userId={userId} projectId={id} />
      <FloatingButton
        onPress={async () => {
          await setIsPreviewVideo(false);
          setIsModalVisible(true);
        }}
      >
        <MaterialCommunityIcons
          name="video-plus"
          size={30}
          color="white"
          style={{ position: "relative" }}
        />
      </FloatingButton>
    </ScrollView>
  );
};

export default VideoTab;

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: "100%",
    maxWidth: 800,
    alignSelf: "center",
  },
  hamburgerMenu: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
  },
  videoGenerationButton: {
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    width: "100%",
  },
  previewRow: {
    paddingVertical: 10,
    backgroundColor: "black",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  previewText: {
    color: Colors.white,
  },
  buttonTitle: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonTitleText: {
    color: Colors.white,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white", // Fullscreen background
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: "white",
    borderRadius: 10,
    alignItems: "center",
  },
  restartIcon: {
    marginLeft: 10, // Adjust the margin as needed
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  noVideosContainer: {
    height: 300,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  noVideosText: {
    color: Colors.white,
  },
});
