import Ionicons from "@expo/vector-icons/Ionicons";
import React from "react";
import { Text, View, StyleSheet } from "react-native";

export const CountdownTimer = ({ countdown }) => {
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours > 0 ? `${hours}:` : ""}${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <View style={styles.iconGroup}>
      <Ionicons name="timer" size={24} color="white" />
      <Text style={styles.text}>{formatTime(countdown)}</Text>
    </View>
  );
};

export const styles = StyleSheet.create({
  iconGroup: {
    alignItems: "center",
  },
  text: {
    color: "white",
  },
});
