export const convertTimeLimitToSeconds = (timeLimit) => {
  switch (timeLimit) {
    case "24hr":
      return 24 * 60 * 60;
    case "1hr":
      return 60 * 60;
    case "5min":
      return 5 * 60;
    case "30sec":
      return 30;
    case "5sec":
      return 5;
    default:
      return 60 * 60; // Default to 1 hour
  }
};
