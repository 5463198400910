import { useActionSheet } from "@expo/react-native-action-sheet";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { getAuth } from "firebase/auth";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  limit,
  startAt,
  getCountFromServer,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Modal,
  Text,
  FlatList,
  ActivityIndicator,
  Image,
  Platform,
} from "react-native";
import Gallery, { GalleryRef } from "react-native-awesome-gallery";

const PAGE_SIZE = 50;

const PhotoGallery = ({ userId, projectId, route }) => {
  if (route && (!userId || !projectId)) {
    userId = route.params.userId;
    projectId = route.params.projectId;
  }
  const { showActionSheetWithOptions } = useActionSheet();

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalImages, setTotalImages] = useState(0); // Add
  const [sortFilterOption, setSortFilterOption] = useState("New"); // Add this line
  const galleryRef = useRef<GalleryRef>(null);

  // Add this useEffect to load the initial rotation from Firestore
  useEffect(() => {
    const loadRotation = async () => {
      const projectRef = doc(getFirestore(), "projects", projectId);
      const projectDoc = await getDoc(projectRef);
      if (projectDoc.exists()) {
        const projectData = projectDoc.data();
        setRotation(projectData.rotation || 0); // Default to 0 if no rotation is set
      } else {
        setRotation(0); // Set default rotation to 0 if project doesn't exist
      }
    };
    loadRotation();
  }, [projectId]);

  const fetchImages = useCallback(async () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const token = user ? await user.getIdToken() : null;

    const db = getFirestore();
    const storage = getStorage();
    const imagesRef = collection(db, "images");
    let orderDirection = "desc";
    let whereCondition = where("excluded", "==", false);
    if (sortFilterOption === "Old") {
      orderDirection = "asc";
    } else if (sortFilterOption === "Hidden") {
      whereCondition = where("excluded", "==", true);
    }

    let countQuery = query(
      imagesRef,
      where("userId", "==", userId),
      where("projectId", "==", projectId),
      whereCondition
    );

    const countResult = await getCountFromServer(countQuery);
    const totalImagesCount = countResult.data().count;

    setTotalImages(totalImagesCount);
    const newTotalPages = Math.ceil(totalImagesCount / PAGE_SIZE);
    setTotalPages(newTotalPages);

    let q = query(
      imagesRef,
      where("userId", "==", userId),
      where("projectId", "==", projectId),
      whereCondition,
      orderBy("order", orderDirection),
      limit(PAGE_SIZE)
    );

    if (currentPage > 1 && images.length > 0) {
      const lastImage = images[images.length - 1];
      q = query(q, startAt(lastImage.order));
    }

    try {
      const querySnapshot = await getDocs(q);
      console.log("docs length:", querySnapshot.docs.length);
      const imagePromises = querySnapshot.docs.map(async (doc) => {
        const imageData = doc.data();

        const imageUrl = imageData.downloadUrl;
        const thumbnailUrl = imageData.downloadUrl200;
        return {
          id: doc.id,
          thumbnail: thumbnailUrl, // Use the thumbnail URL
          full: imageUrl, // Use the full image URL
          ...imageData,
        };
      });
      let fetchedImages = await Promise.all(imagePromises);

      setImages(fetchedImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
    setIsFetching(false);
  }, [projectId, userId, sortFilterOption, currentPage]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchImages().then(() => {
      setRefreshing(false);
    });
  }, [fetchImages]);

  useEffect(() => {
    console.log("useEffect for fetchImages triggered", {
      projectId,
      userId,
      sortFilterOption,
    });
    fetchImages();
  }, [projectId, userId, sortFilterOption, fetchImages]); // Add sortFilterOption to the dependency array

  const shareImage = async () => {
    console.log("shareImage called");
    if (selectedImage) {
      if (Platform.OS === "web") {
        // Open the image in a new tab as a workaround to CORS issues
        window.open(selectedImage.full, "_blank");
        console.log("Image opened in new tab");
      } else {
        // Existing logic for non-web platforms
        try {
          const localUri = await FileSystem.downloadAsync(
            selectedImage.full,
            FileSystem.cacheDirectory + selectedImage.full.split("/").pop()
          );
          await Sharing.shareAsync(localUri.uri);
          console.log("Share successful");
        } catch (error) {
          console.error("Error sharing", error);
        }
      }
    }
  };

  const hamburgerOnPress = useCallback(
    (imageId) => {
      console.log("hamburgerOnPress called", imageId);

      const hideUnhideOption =
        sortFilterOption === "Hidden" ? "Unhide" : "Hide";
      const options = [hideUnhideOption, "Download", "Cancel"];
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 2;

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
          useModal: true,
        },
        async (selectedIndex: number) => {
          switch (selectedIndex) {
            case 1:
              shareImage();
              break;

            case destructiveButtonIndex:
              const db = getFirestore();
              const imageDocRef = doc(db, "images", imageId);

              try {
                const imageDoc = await getDoc(imageDocRef);
                if (imageDoc.exists()) {
                  const selectedImage = imageDoc.data();
                  console.log("Hiding image", selectedImage.full);
                  console.log("about to HIDE/UNHIDE");
                  await updateDoc(imageDocRef, {
                    excluded: !selectedImage.excluded,
                  });
                  console.log(
                    selectedImage.excluded
                      ? "Image unhidden successfully"
                      : "Image hidden successfully"
                  );
                  setSelectedImage(null);
                  console.log("===fetching images");
                  fetchImages();
                } else {
                  console.log("Image not found in Firestore");
                }
              } catch (error) {
                console.error("Error hiding/unhiding image:", error);
              }
              break;

            case cancelButtonIndex:
              break;
          }
        }
      );
    },
    [
      fetchImages,
      shareImage,
      showActionSheetWithOptions,
      sortFilterOption, // Add sortFilterOption to the dependency array
    ]
  );

  const showSortFilterActionSheet = () => {
    const options = ["New", "Old", "Hidden", "Cancel"];
    const cancelButtonIndex = 3;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            await setSortFilterOption("New");
            // await setImages([]);
            // await setCurrentPage(0); // Reset to page 1
            await setCurrentPage(1);

            break;
          case 1:
            await setSortFilterOption("Old");
            // await setImages([]);
            // await setCurrentPage(0); // Reset to page 1
            await setCurrentPage(1);

            break;
          case 2:
            await setSortFilterOption("Hidden");
            // await setImages([]);
            // await setCurrentPage(0); // Reset to page 1
            await setCurrentPage(1);

            break;
        }
      }
    );
  };

  const renderItem = useCallback(
    ({ item, index }) => {
      const onPress = () => {
        setSelectedImage(item);
      };

      const onLongPress = () => {
        hamburgerOnPress(item.id);
      };

      return (
        <TouchableOpacity onPress={onPress} onLongPress={onLongPress}>
          <View style={styles.imageContainer}>
            <Image
              style={[
                styles.thumbnail,
                { transform: [{ rotate: `${rotation}deg` }] },
              ]}
              source={{ uri: item.thumbnail }}
            />
            <Text style={styles.imageLabel}>#{item.order + 1}</Text>
          </View>
        </TouchableOpacity>
      );
    },
    [rotation, setSelectedImage]
  );

  const handleRotation = async () => {
    setRotation((prevRotation) => {
      const newRotation = (prevRotation + 90) % 360;
      // Save the new rotation to Firestore
      const projectRef = doc(getFirestore(), "projects", projectId);
      updateDoc(projectRef, { rotation: newRotation });
      return newRotation;
    });
  };

  const renderFooter = () => {
    if (!isFetching && !refreshing) return null;
    return <ActivityIndicator size="large" color="#0000ff" />;
  };

  const renderImage = ({ item }: any) => {
    const goToNextImage = () => {
      if (galleryRef.current) {
        const currentIndex = images.findIndex(
          (image) => image === selectedImage
        );
        const nextIndex = (currentIndex + 1) % images.length; // Loop back to the first image if at the end
        galleryRef.current.setIndex(nextIndex);
      }
    };

    const goToPreviousImage = () => {
      if (galleryRef.current) {
        const currentIndex = images.findIndex(
          (image) => image === selectedImage
        );
        const previousIndex =
          (currentIndex - 1 + images.length) % images.length; // Loop to the last image if at the beginning
        galleryRef.current.setIndex(previousIndex);
      }
    };

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return "";
      const date = new Date(timestamp);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid timestamp:", timestamp);
        return "Invalid Date";
      }

      // Check if the year is unreasonably large
      if (date.getFullYear() > 9999) {
        console.error("Invalid year in timestamp:", timestamp);
        return "Invalid Date";
      }

      return date
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })
        .replace(",", " -");
    };

    return (
      <View style={StyleSheet.absoluteFillObject}>
        <Image
          source={{ uri: item.full }} // Updated to use item.full
          resizeMode="contain"
          style={[
            StyleSheet.absoluteFillObject,
            { transform: [{ rotate: `${rotation}deg` }] }, // Apply rotation to the selected image
          ]}
        />
        <Text style={styles.timestampText}>
          {formatTimestamp(item.generation / 1000)}
        </Text>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={() => setSelectedImage(null)}
        >
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.hamburgerButton}
          onPress={() => hamburgerOnPress(item.id)}
        >
          <MaterialCommunityIcons name="menu" size={24} color="black" />
        </TouchableOpacity>
        {/* Left Chevron */}
        <TouchableOpacity
          style={[styles.chevronButton, styles.chevronLeft]}
          onPress={goToPreviousImage}
        >
          <MaterialCommunityIcons name="chevron-left" size={48} color="white" />
        </TouchableOpacity>
        {/* Right Chevron */}
        <TouchableOpacity
          style={[styles.chevronButton, styles.chevronRight]}
          onPress={goToNextImage}
        >
          <MaterialCommunityIcons
            name="chevron-right"
            size={48}
            color="white"
          />
        </TouchableOpacity>
      </View>
    );
  };
  console.log(images);

  return (
    <>
      <View style={styles.topBar}>
        <TouchableOpacity
          style={styles.topBarLeft}
          onPress={showSortFilterActionSheet}
        >
          <Text style={styles.topBarText}>{sortFilterOption}</Text>
          <MaterialCommunityIcons name="chevron-down" size={16} color="black" />
        </TouchableOpacity>
      </View>
      <FlatList
        data={images}
        extraData={images[0]?.id}
        keyExtractor={(item) => item.thumbnail} // Assuming each image URL is unique
        renderItem={renderItem}
        numColumns={3}
        columnWrapperStyle={styles.row}
        // onEndReached={loadMoreImages}
        onEndReachedThreshold={0.1}
        ListFooterComponent={renderFooter}
        removeClippedSubviews={true}
        onRefresh={onRefresh} // Add the onRefresh prop
        refreshing={refreshing} // Add the refreshing prop
        windowSize={3}
        initialNumToRender={9}
      />
      <View style={styles.pagination}>
        {currentPage > 1 ? (
          <TouchableOpacity
            onPress={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            <Text style={styles.paginationText}>Previous</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.paginationPlaceholder} />
        )}
        <Text style={styles.paginationText}>
          Page {currentPage} of {totalPages}
        </Text>
        {currentPage < totalPages ? (
          <TouchableOpacity
            onPress={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          >
            <Text style={styles.paginationText}>Next</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.paginationPlaceholder} />
        )}
      </View>
      {selectedImage !== null && (
        <Modal animationType="slide" transparent={true} visible={true}>
          <Gallery
            ref={galleryRef}
            keyExtractor={(item) => item.id}
            data={images}
            initialIndex={images.findIndex((image) => image === selectedImage)}
            onIndexChange={(index) => setSelectedImage(images[index])}
            onSwipeToClose={() => setSelectedImage(null)}
            renderItem={renderImage}
          />
        </Modal>
      )}
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.rotateButton} onPress={handleRotation}>
          <Feather name="rotate-cw" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  topBar: {
    height: 30,
    backgroundColor: "#e0e0e0",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topBarLeft: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: 10,
    alignItems: "center",
  },
  topBarRight: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 10,
  },
  topBarText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  thumbnail: {
    width: 100,
    height: 100,
    margin: 1.5,
  },
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingTop: 50,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  fullscreenImage: {
    width: "90%",
    height: "80%",
  },
  closeText: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 15,
    padding: 8,
    marginTop: 50,
  },
  row: {
    flex: 1,
    justifyContent: "space-around",
  },
  buttonContainer: {
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  rotateButton: {
    padding: 10,
  },
  imageContainer: {
    position: "relative",
    width: 100,
    height: 100,
    margin: 1.5,
  },
  imageLabel: {
    position: "absolute",
    bottom: 5,
    right: 5,
    color: "white",
    fontWeight: "bold",
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 3,
  },
  closeButton: {
    position: "absolute",
    left: 10,
    top: 50,
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 15,
    padding: 8,
  },
  hamburgerButton: {
    position: "absolute",
    right: 10,
    top: 50,
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 15,
    padding: 8,
  },
  chevronButton: {
    position: "absolute",
    top: "50%",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: 25,
    padding: 10,
  },
  chevronLeft: {
    left: 10,
  },
  chevronRight: {
    right: 10,
  },
  pagination: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
  },
  paginationText: {
    fontSize: 16,
  },
  paginationPlaceholder: {
    width: 70, // Adjust this width to match the width of the "Previous" and "Next" buttons
  },
  timestampText: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
  },
});

export default PhotoGallery;
