export const convertSecondsToTimeLimit = (seconds) => {
  if (seconds >= 24 * 60 * 60) {
    return "24hr";
  } else if (seconds >= 60 * 60) {
    return "1hr";
  } else if (seconds >= 5 * 60) {
    return "5min";
  } else if (seconds >= 30) {
    return "30sec";
  } else if (seconds >= 5) {
    return "5sec";
  } else {
    return "30sec";
  }
};
