const Colors = {
  primary: "#A5C8D3",
  secondary: "#FFE5CC",
  accent1: "#68b687",
  accent2: "#EF886F",
  accent3: "#8A8E8A",
  muted: "#F6F6F6",
  primaryText: "#325964",
  secondaryText: "#FAFAFA",
  black: "#000000",
  white: "#FFFFFF",
  disabled: "#D3D3D3",
  disabledText: "#8A8E8A",
};

export default Colors;
