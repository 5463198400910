// Signup.js
import { useRef, useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  KeyboardAvoidingView,
  Text,
} from "react-native";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigation } from "@react-navigation/native";
import Button, { ButtonVariant } from "../components/Button";
import IconImage from "../../assets/adaptive-icon.png";
import FormInput from "../components/FormInput";

const auth = getAuth();

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const navigation = useNavigation();
  const passwordRef = useRef(null);

  const onChangeEmail = (text) => {
    setEmail(text);
  };
  const onChangePassword = (text) => {
    setPassword(text);
  };

  const onPress = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigation.navigate("ModeSelector");
      })
      .catch((error) => {
        const errorMessage = error.message?.replace("Firebase: ", "") ?? "";
        setErrorMessage(errorMessage);
      });
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Image
          source={IconImage}
          style={{ width: 100, height: 100, marginBottom: 20 }}
        />
        <FormInput
          label="Email"
          placeholder="Enter your email"
          onChangeText={onChangeEmail}
          onSubmitEditing={() => passwordRef.current.focus()}
        />
        <FormInput
          label="Password"
          placeholder="Enter your password"
          onChangeText={onChangePassword}
          secureTextEntry={true}
          ref={passwordRef}
          onSubmitEditing={onPress}
          returnKeyType="done"
        />
        {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
        <Button
          title="Signup"
          onPress={onPress}
          variant={ButtonVariant.Primary}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingHorizontal: 50,
  },
  errorText: {
    color: "red",
    width: "70%",
    marginTop: 5,
  },
});

export default Signup;
