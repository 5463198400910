import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { Feather } from "@expo/vector-icons";
import {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";
import {
  DrawerContentScrollView,
  DrawerItem,
  createDrawerNavigator,
} from "@react-navigation/drawer";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useFonts } from "expo-font";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Button,
  ActivityIndicator,
} from "react-native";

import { auth } from "./firebaseConfig";
import Colors from "./src/components/Colors";
import ConfirmSwitchMode from "./src/components/ConfirmSwitchMode";
import ProjectCamera from "./src/components/ProjectCamera/ProjectCamera";
import SettingsButton from "./src/components/SettingsButton";
import Admin from "./src/screens/Admin";
import ForgotMyPassword from "./src/screens/ForgotMyPassword";
import LandingScreen from "./src/screens/Landing";
import Login from "./src/screens/Login";
import ModeSelector from "./src/screens/ModeSelector";
import NewProject from "./src/screens/NewProject";
import Onboard from "./src/screens/Onboard";
import PhotoGallery from "./src/screens/PhotoGallery";
import Privacy from "./src/screens/Privacy";
import Profile from "./src/screens/Profile";
import Project from "./src/screens/Project";
import ProjectsList from "./src/screens/ProjectsList";
import Signup from "./src/screens/Signup";
import Support from "./src/screens/Support";
import Terms from "./src/screens/Terms";
import VideosGallery from "./src/screens/VideosGallery";

const Drawer = createDrawerNavigator();

const Stack = createNativeStackNavigator();

const CustomDrawerContent = (props) => {
  const navigation = useNavigation();
  return (
    <DrawerContentScrollView
      {...props}
      style={{
        backgroundColor: Colors.primaryText,
      }}
    >
      <DrawerItem
        label="Login"
        onPress={() => {
          props.navigation.closeDrawer();
          navigation.navigate("Login");
        }}
        labelStyle={{ color: Colors.white }}
      />
      <DrawerItem
        label="Signup"
        onPress={() => {
          props.navigation.closeDrawer();
          navigation.navigate("Signup");
        }}
        labelStyle={{ color: Colors.white }}
      />
    </DrawerContentScrollView>
  );
};

const LandingSreenDrawer = () => {
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerPosition: "left",
        headerTintColor: Colors.white,
        headerStyle: {
          backgroundColor: Colors.black,
          borderWidth: 0, // Add this line
        },
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Home"
        component={LandingScreen}
        options={{
          drawerLabel: () => null,
          headerTitle: "",
        }}
      />
    </Drawer.Navigator>
  );
};

const App = () => {
  const [loggedIn, setLoggedIn] = useState(null); // Change initial state to null

  const [fontsLoaded] = useFonts({
    MilaBold: require("./assets/fonts/MilaBold.otf"),
    MilaRegular: require("./assets/fonts/MilaRegular.otf"),
    MilaLight: require("./assets/fonts/MilaLight.otf"),
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    Roboto_900Black_Italic,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (!fontsLoaded || loggedIn === null) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    );
  }

  return (
    <ActionSheetProvider>
      <NavigationContainer linking={{ enabled: true }}>
        <Stack.Navigator>
          {loggedIn ? (
            <>
              <Stack.Screen
                name="ProjectsList"
                component={ProjectsList}
                options={{
                  title: "Projects",
                  headerRight: SettingsButton,
                }}
              />
              <Stack.Screen
                name="Project"
                component={Project}
                options={({ navigation }) => ({
                  // Access navigation prop here
                  title: "Project",
                  headerLeft: () => (
                    <TouchableOpacity
                      style={{ marginLeft: 10 }}
                      onPress={() =>
                        navigation.reset({
                          index: 0,
                          routes: [{ name: "ProjectsList" }],
                        })
                      }
                    >
                      <Feather name="arrow-left" size={24} color="black" />
                    </TouchableOpacity>
                  ),
                })}
              />
              <Stack.Screen
                name="NewProject"
                component={NewProject}
                options={{
                  title: "New Project",
                  headerRight: () => <Button title="Save" disabled />,
                }}
              />
              <Stack.Screen
                name="Profile"
                component={Profile}
                options={{
                  title: "Profile",
                }}
              />
              <Stack.Screen
                name="ConfirmSwitchMode"
                component={ConfirmSwitchMode}
                options={{
                  title: "Confirm Switch Mode",
                }}
              />

              <Stack.Screen
                name="ModeSelector"
                component={ModeSelector}
                options={{
                  title: "Mode",
                  headerRight: () => <Button title="Next" disabled />,
                }}
              />
              <Stack.Screen
                name="PhotoGallery"
                component={PhotoGallery}
                options={{
                  title: "Photo Gallery",
                }}
              />
              <Stack.Screen
                name="VideoGallery"
                component={VideosGallery}
                options={{
                  title: "Video Gallery",
                  headerRight: () => (
                    <Feather name="plus-circle" size={24} color="black" />
                  ),
                }}
              />
              <Stack.Screen
                name="Camera"
                component={ProjectCamera}
                options={{
                  orientation: "landscape",
                  headerShown: false,
                }}
              />
            </>
          ) : (
            <>
              <Stack.Screen
                name="Landing"
                component={LandingSreenDrawer}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="Onboard"
                component={Onboard}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen name="Login" component={Login} />
              <Stack.Screen name="Signup" component={Signup} />
              <Stack.Screen
                name="ForgotMyPassword"
                component={ForgotMyPassword}
              />
            </>
          )}
          <Stack.Screen
            name="support"
            component={Support}
            options={{
              title: "Support",
            }}
          />
          <Stack.Screen
            name="admin"
            component={Admin}
            options={{
              title: "Admin",
            }}
          />

          <Stack.Screen
            name="terms"
            component={Terms}
            options={{
              title: "Terms and Conditions",
            }}
          />
          {/* <Stack.Screen name="Feed" component={PublicFeed} /> */}
          <Stack.Screen
            name="privacy"
            component={Privacy}
            options={{
              title: "Privacy Policy",
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </ActionSheetProvider>
  );
};

export default App;
