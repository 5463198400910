import { Feather } from "@expo/vector-icons";
import { getAuth } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";

import { analytics, db } from "../../firebaseConfig";
import Colors from "../components/Colors";
import FloatingButton from "../components/FloatingButton";
import ProjectCard from "../components/ProjectCard";
import { ProjectStates } from "../config/projectStates";
import getPlaceholderImageForId from "../utils/getPlaceholderImageForId";
import { getData, storeData } from "../utils/storage";
import { logEvent } from "firebase/analytics";

const auth = getAuth();

const dummyProjectValues = [
  {
    health: ProjectStates.capturing,
    battery: "100%",
    imageCount: 129,
    lastImage: 1701447392000,
  },
  {
    health: ProjectStates.paused,
    battery: "47%",
    imageCount: 23,
    lastImage: 1700359064920,
  },
  {
    health: ProjectStates.error,
    battery: "1%",
    imageCount: 1290,
    lastImage: 1701443792000,
  },
];

const ProjectList = ({ navigation }) => {
  const userId = auth.currentUser.uid;

  const [mode, setMode] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchMode = async () => {
      const storedMode = await getData("mode");
      setMode(storedMode);
    };

    fetchMode();
  }, []);

  useEffect(() => {
    const projectId = async () => {
      const storedprojectId = await getData("projectId");
      setProjectId(storedprojectId);
    };
    projectId();
  }, []);

  useEffect(() => {
    const projectCollection = collection(db, "projects");
    const q = query(projectCollection, where("userId", "==", userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let projectList = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      //TODO: Reattempt to move this to the backend. It was tricky to do with the orderBy?
      projectList = projectList.filter((project) => project.deleted !== true);

      // Sort projects by lastImageTime in descending order
      projectList.sort((a, b) => b.lastImageTime - a.lastImageTime);

      setProjects(projectList);
    });

    // Clean up the onSnapshot listener when the component is unmounted
    return () => unsubscribe();
  }, [userId]);

  const EmptyState = () => (
    <View>
      <Text>No projects yet</Text>
    </View>
  );

  const onNewProjectClick = () => {
    logEvent(analytics, "new_project_click", {
      method: "button_press",
    });
    navigation.navigate("NewProject");
  };

  const NoActiveProjects = () => (
    <View style={styles.noActiveProjects}>
      <Text>Select a project to start recording!</Text>
      <Feather name="camera" size={24} color="black" />
    </View>
  );

  const onProjectClick = async (id) => {
    // if (mode === "camera") {
    //   await storeData("projectId", id);
    //   setProjectId(id);
    // }
  };

  return (
    <View style={styles.listContainer}>
      {/* {projectId === null && <NoActiveProjects />} */}
      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
        {projects.length > 0 ? (
          projects.map((project) => {
            const thumbnail = getPlaceholderImageForId(project.id);
            return (
              <ProjectCard
                id={project.id}
                key={project.id}
                name={project.name}
                thumbnail={thumbnail}
                battery={project.batteryLevel}
                lastImage={project.lastImageTime}
                health={project.health}
                numImages={project.imagesCount}
                onClick={() => onProjectClick(project.id)}
                isActive={projectId === project.id}
              />
            );
          })
        ) : (
          <EmptyState />
        )}
      </ScrollView>
      <FloatingButton onPress={onNewProjectClick}>
        <Text style={styles.floatingButtonText}>+</Text>
      </FloatingButton>
    </View>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    paddingVertical: 16,
    flex: 1,
    position: "relative",
  },
  scrollViewContainer: {
    rowGap: 16,
    paddingHorizontal: 16,
  },
  noActiveProjects: {
    flexDirection: "row",
    padding: 16,
    gap: 16,
    borderColor: "#d3d3d3",
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: "#e0ffff",
    marginBottom: 16,
  },
  floatingButtonText: {
    fontSize: 30,
    color: Colors.secondaryText,
    fontWeight: "bold",
  },
});

export default ProjectList;
