import { Text, View, StyleSheet, Image, TextInput, Button } from "react-native";
import RemoteImage from "../../assets/remote.png";
import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const auth = getAuth();

const NewProject = ({ navigation }) => {
  const [name, setName] = useState("New Project");
  const userId = auth.currentUser.uid;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <Button onPress={handleSave} title="Save" />,
    });
  }, [navigation, name]);

  const handleSave = async () => {
    const newProject = {
      userId,
      name,
      status: "true",
    };

    const docRef = await addDoc(collection(db, "projects"), newProject);
    navigation.navigate("Project", { id: docRef.id });
  };

  const onNameChange = (text) => {
    setName(text);
  };

  const onSubmitEditing = () => {
    handleSave();
  };

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image source={RemoteImage} style={styles.image} />
        </View>
        <View style={styles.formContainer}>
          <Text style={styles.label}>Name</Text>
          <TextInput
            placeholder="Camera"
            onChangeText={onNameChange}
            value={name}
            style={styles.input}
            onSubmitEditing={onSubmitEditing}
            returnKeyType="done"
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    padding: 20,
  },
  container: {
    backgroundColor: "white",
    padding: 20,
    minHeight: "70%",
    borderRadius: 20,
    borderWidth: 1,
    // nice pastel gray
    borderColor: "#d3d3d3",
    // flex: 1,
  },
  imageContainer: {
    flex: 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 20,
  },
  label: {
    fontWeight: "bold",
  },
  input: {
    height: 40,
    width: 200,
    borderBottomColor: "gray",
    borderBottomWidth: 1,
  },
  image: {
    width: "30%",
    height: undefined,
    aspectRatio: 1,
    borderRadius: 100,
  },
});

export default NewProject;
