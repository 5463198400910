import { getAuth, deleteUser } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  View,
  Button,
  Text,
  TouchableOpacity,
  StyleSheet,
  Linking,
  Alert,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { getData } from "../utils/storage";
import Type from "../components/Type";
import Constants from "expo-constants"; // Import Constants
import * as Application from "expo-application"; // Import Application

const auth = getAuth();

const Profile = ({ navigation }) => {
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const fetchMode = async () => {
      const storedMode = await getData("mode");
      setMode(storedMode);
    };

    fetchMode();
  }, []);

  const onSignout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const onFeedback = () => {
    const email = "aryangoharzad@gmail.com";
    const subject = encodeURIComponent("Feedback for the App");
    const body = encodeURIComponent("Type your feedback here");
    const url = `mailto:${email}?subject=${subject}&body=${body}`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log(`Don't know how to open this URL: ${url}`);
      }
    });
  };

  // Version information from Constants and Application
  const bundleVersion = Constants.expoConfig?.extra?.bundleVersion || null;
  const appVersion = Application.nativeApplicationVersion;

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <View style={styles.body}>
          <View style={styles.formRow}>
            <Type>Photo Limit: 10000 pictures per album</Type>
            {/* <Text>Mode: {mode}</Text> */}
            {/* <TouchableOpacity onPress={() => navigation.navigate("ModeSelector")}>
            <Feather name="edit-3" size={16} color="black" />
          </TouchableOpacity> */}
          </View>

          <View style={styles.feedbackButton}>
            <Button onPress={onFeedback} title="Submit Feedback" />
          </View>
          <View style={styles.signOutButton}>
            <Button onPress={onSignout} title="Sign out" />
          </View>
          <View style={styles.deleteUserButton}>
            <Button
              onPress={() => {
                Alert.alert(
                  "Delete Account",
                  "Are you sure you want to delete your account? This action cannot be undone.",
                  [
                    {
                      text: "Cancel",
                      style: "cancel",
                    },
                    {
                      text: "Delete",
                      onPress: async () => {
                        const user = auth.currentUser;
                        if (user) {
                          deleteUser(user)
                            .then(() => {
                              console.log("User deleted successfully");
                              // Handle post-deletion logic here, e.g., navigate to a login screen
                            })
                            .catch((error) => {
                              console.error("Error deleting user:", error);
                              // Handle errors here, e.g., show an error message
                            });
                        }
                      },
                    },
                  ],
                  { cancelable: false }
                );
              }}
              title="Delete Account"
            />
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.versionText}>
            Version: {appVersion || "null"}({bundleVersion || "null"})
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  formRow: { flexDirection: "row" },
  signOutButton: {
    marginTop: 50,
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 20,
  },
  feedbackButton: {
    marginTop: 20,
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 20,
  },
  deleteUserButton: {
    marginTop: 20,
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 20,
  },
  container: {
    flex: 1,

    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  body: {
    flex: 1,
    padding: 16,
  },
  footer: {
    padding: 16,
  },
  button: {
    borderRadius: 20,
    borderColor: "#000",
    padding: 10,
    elevation: 2,
  },
  versionText: {
    color: "grey",
    // Removed position: "absolute" and alignSelf: "center",
    textAlign: "center", // Center text horizontally
    marginBottom: 10, // Ensure it stays at the bottom
  },
});

export default Profile;
