import React from "react";
import { View, Text, Button, Linking, StyleSheet } from "react-native";

const Support = () => {
  const handleEmailPress = () => {
    Linking.openURL("mailto:arrygoo@gmail.com");
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        For support please email arrygoo@gmail.com
      </Text>
      <Button title="Email Us" onPress={handleEmailPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  text: {
    marginBottom: 20,
  },
});

export default Support;
