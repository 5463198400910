import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Button,
  ScrollView,
} from "react-native";
import CameraImage from "../../assets/camera.png";
import RemoteImage from "../../assets/remote.png";
import { SafeAreaView } from "react-native-safe-area-context";
import { db, auth } from "../../firebaseConfig";
import { ref, set } from "firebase/database";
import { storeData } from "../utils/storage";

enum ModeOptions {
  Camera = "Camera",
  Remote = "Remote",
}

const ModeSelector = ({ navigation }) => {
  const [selectedOption, setSelectedOption] = useState<ModeOptions | null>(
    null
  );

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const handleNext = async () => {
      // const userId = auth.currentUser.uid; // Get the current user's ID
      storeData("mode", selectedOption.toLowerCase());

      navigation.navigate("ProjectsList");

      // Add a new document in collection "users" with ID = userId
      // set(ref(db, "users/" + userId), {
      //   mode: selectedOption.toLowerCase(),
      // });

      console.log("Next button pressed");
    };

    navigation.setOptions({
      title: "Mode",
      headerRight: () => <Button onPress={handleNext} title="Next" />,
    });
  }, [navigation, selectedOption]);

  return (
    <SafeAreaView>
      <ScrollView>
        <View style={styles.optionsContainer}>
          <TouchableOpacity
            style={[
              styles.optionContainer,
              selectedOption === "Camera" && styles.selected,
            ]}
            onPress={() => handleSelect(ModeOptions.Camera)}
          >
            <Text style={styles.optionHeader}>Camera</Text>
            <Text style={styles.optionDescription}>
              Use this phone to take pictures
            </Text>
            <Image
              source={CameraImage}
              style={styles.optionImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.optionContainer,
              selectedOption === "Remote" && styles.selected,
            ]}
            onPress={() => handleSelect(ModeOptions.Remote)}
          >
            <Text style={styles.optionHeader}>Remote</Text>
            <Text style={styles.optionDescription}>
              Use this phone as a remote
            </Text>
            <Image
              source={RemoteImage}
              style={styles.optionImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  optionImage: {
    width: "60%", // This will make the image take the full width of its parent
    height: undefined, // This will allow the image to scale its height according to its width while maintaining its aspect ratio
    aspectRatio: 1, // Assuming the image is square, if not, change this to the aspect ratio of the image
  },
  optionsContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 40,
    alignItems: "center",
    width: "100%",
    paddingTop: 20,
  },
  optionContainer: {
    backgroundColor: "#fff",
    borderColor: "#d3d3d3",
    borderWidth: 1,
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
    gap: 10,
    minWidth: "70%",
  },
  selected: {
    borderColor: "#0000ff", // Change this to the color you want for the selected option
  },
  optionHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
  optionDescription: {
    fontSize: 16,
  },
});

export default ModeSelector;
