import React, { ReactNode } from "react";
import {
  Text,
  StyleSheet,
  TextProps,
  StyleProp,
  TextStyle,
} from "react-native";

export enum TypeVariant {
  LargeTitle,
  MediumTitle,
  SmallTitle,
  Header,
  Body,
}

interface TypeProps extends TextProps {
  children: ReactNode;
  style?: StyleProp<TextStyle>;
  variant?: TypeVariant;
}

const Type = ({
  children,
  style = {},
  variant = TypeVariant.Body,
  ...props
}: TypeProps) => {
  let textStyle = {};

  switch (variant) {
    case TypeVariant.LargeTitle:
      textStyle = { fontSize: 32, fontFamily: "Roboto_700Bold" };
      break;
    case TypeVariant.MediumTitle:
      textStyle = { fontSize: 24, fontFamily: "Roboto_500Medium" };
      break;
    case TypeVariant.SmallTitle:
      textStyle = { fontSize: 20, fontFamily: "Roboto_500Medium" };
      break;
    case TypeVariant.Header:
      textStyle = { fontSize: 24, fontFamily: "Roboto_500Medium" };
      break;
    case TypeVariant.Body:
    default:
      textStyle = { fontSize: 16, fontFamily: "Roboto_400Regular" };
      break;
  }

  return (
    <Text style={[styles.defaultStyle, textStyle, style]} {...props}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  defaultStyle: {
    fontFamily: "Roboto_400Regular",
  },
});

export default Type;
