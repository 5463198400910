import { useNavigation } from "@react-navigation/native";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useRef } from "react";
import {
  Text,
  TextInput,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  Image,
  Platform,
} from "react-native";
import IconImage from "../../assets/adaptive-icon.png";
import Button, { ButtonVariant } from "../components/Button";
import FormInput from "../components/FormInput";

const auth = getAuth();

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const navigation = useNavigation();
  const emailRef = useRef();
  const passwordRef = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onChangeEmail = (text) => {
    setEmail(text);
  };
  const onChangePassword = (text) => {
    setPassword(text);
  };

  const loginOnPress = () => {
    console.log("Email: ", email);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("---logged in user---", user);

        // Navigate to the Home screen after successful login
        // navigation.navigate("ModeSelector");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message?.replace("Firebase: ", "") ?? "";
        // JSON of error
        console.log("Error logging in: ", error);
        setErrorMessage(errorMessage);
      });
  };

  const registerOnPress = () => {
    navigation.navigate("Signup");
  };

  const forgotPasswordOnPress = () => {
    navigation.navigate("ForgotMyPassword");
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Image
          source={IconImage}
          style={{ width: 100, height: 100, marginBottom: 20 }}
        />

        <FormInput
          label="Email"
          placeholder="Enter your email"
          onChangeText={onChangeEmail}
          onSubmitEditing={() => passwordRef.current.focus()}
          ref={emailRef}
        />
        <FormInput
          label="Password"
          placeholder="Enter your password"
          onChangeText={onChangePassword}
          secureTextEntry={true}
          ref={passwordRef}
          onSubmitEditing={loginOnPress}
          returnKeyType="done"
        />
        {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}

        <View style={styles.buttonsContainer}>
          <Button
            title="Login"
            onPress={loginOnPress}
            variant={ButtonVariant.Primary}
          />
          <Button
            onPress={registerOnPress}
            title="Register"
            variant={ButtonVariant.Secondary}
          />
          <Button
            onPress={forgotPasswordOnPress}
            title="Forgot your password?"
            variant={ButtonVariant.Tertiary}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingHorizontal: 50,
  },
  buttonsContainer: {
    gap: 20,
    marginTop: 60,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  errorText: {
    color: "red",
    width: 200,
    marginTop: 5,
  },
});

export default Login;
