export const formatLastImageTime = (timestamp) => {
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_MONTH = 2592000;

  if (!timestamp) {
    return "-";
  }

  let diffInSeconds = Math.floor((Date.now() - timestamp) / 1000);
  diffInSeconds = Math.round(diffInSeconds / 5) * 5; // Round to the nearest 5

  if (diffInSeconds < SECONDS_IN_MINUTE) {
    return `${diffInSeconds}s ago`;
  } else if (diffInSeconds < SECONDS_IN_HOUR) {
    return `${Math.floor(diffInSeconds / SECONDS_IN_MINUTE)}m ago`;
  } else if (diffInSeconds < SECONDS_IN_DAY) {
    return `${Math.floor(diffInSeconds / SECONDS_IN_HOUR)}h ago`;
  } else if (diffInSeconds < SECONDS_IN_MONTH) {
    return `${Math.floor(diffInSeconds / SECONDS_IN_DAY)}d ago`;
  } else {
    return `${Math.floor(diffInSeconds / SECONDS_IN_MONTH)}mo ago`;
  }
};

export const formatTimestampToReadableDate = (timestamp) => {
  if (!timestamp) {
    return "-";
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};
