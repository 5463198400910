import { TouchableOpacity } from "react-native";
import Colors from "./Colors";
import Type from "./Type";
import React from "react";

export enum ButtonVariant {
  Primary,
  Secondary,
  Tertiary,
}

// Typescript type definitions for button props
type ButtonProps = {
  onPress: () => void;
  title: string | React.ReactNode; // Updated to accept either string or React component
  style?: object;
  variant?: ButtonVariant;
  textStyle?: object;
  disabled?: boolean;
};

const VariantToColor = (variant: ButtonVariant, disabled: boolean) => {
  if (disabled) {
    return {
      backgroundColor: Colors.disabled,
      color: Colors.disabledText,
    };
  }
  switch (variant) {
    case ButtonVariant.Primary:
      return {
        backgroundColor: Colors.accent1,
        color: Colors.secondaryText,
      };
    case ButtonVariant.Secondary:
      return {
        backgroundColor: Colors.muted,
        color: Colors.accent1,
      };
    case ButtonVariant.Tertiary:
      return {
        // transparent
        backgroundColor: "#00000000",
        color: Colors.accent1,
      };
  }
};

const Button = ({
  onPress,
  title,
  style,
  textStyle,
  variant = ButtonVariant.Primary,
  disabled = false,
}: ButtonProps) => (
  <TouchableOpacity
    onPress={disabled ? undefined : onPress}
    style={{
      backgroundColor: VariantToColor(variant, disabled).backgroundColor,
      paddingVertical: 16,
      paddingHorizontal: 32,
      borderRadius: 100,
      opacity: disabled ? 0.5 : 1,
      ...style,
    }}
    disabled={disabled}
  >
    {typeof title === "string" ? (
      <Type
        style={[
          {
            color: VariantToColor(variant, disabled).color,
            fontWeight: "600",
          },
          textStyle,
        ]}
      >
        {title}
      </Type>
    ) : (
      title
    )}
  </TouchableOpacity>
);

export default Button;
