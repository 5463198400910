import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  ScrollView,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  formatLastImageTime,
  formatTimestampToReadableDate,
} from "./ProjectCamera/utils/formatLastImageTime";
import VideoModal from "./VideoModal";
import { statusMap } from "../utils/statusMap"; // Import the status map
import deleteVideo from "../utils/deleteVideo";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { formatLength } from "../utils/formatLength"; // Import the formatLength utility

export const VideoThumbnailList = ({ userId, projectId }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [mappedVideoStatus, setMappedVideoStatus] = useState(""); // New state for mapped video status
  const [isLoading, setIsLoading] = useState({});
  const { showActionSheetWithOptions } = useActionSheet();

  const handleVideoLongPress = ({ videoId }) => {
    const options = ["Delete", "Cancel"];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case destructiveButtonIndex:
            // Delete logic here
            deleteVideo({ videoId, onClose: fetchThumbnails });
            break;
          case cancelButtonIndex:
            break;
        }
      }
    );
  };

  const fetchThumbnail = useCallback(async () => {
    const db = getFirestore();
    const storage = getStorage();
    const imagesCollection = collection(db, "images");
    const q = query(
      imagesCollection,
      where("userId", "==", userId),
      where("projectId", "==", projectId),
      where("excluded", "==", false),
      orderBy("order", "desc"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      const imageRef = ref(
        storage,
        `${userId}/${projectId}/images/${data.filename}`
      );
      const url = await getDownloadURL(imageRef);
      setThumbnailURL(url);
    }
  }, [userId, projectId]);

  const fetchThumbnails = useCallback(() => {
    const db = getFirestore();
    const q = query(
      collection(db, "videos"),
      where("userId", "==", userId),
      where("projectId", "==", projectId),
      where("isPreview", "==", false),
      orderBy("generation", "desc")
    );

    console.log("Params", { userId, projectId });
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const thumbnailURLs = querySnapshot.docs.map((doc) => {
        if (!doc || !doc.data()) {
          return;
        }
        const data = doc.data();
        const generatedTimeInMillis = data.generation / 1000; // Convert microseconds to milliseconds

        // Map the status
        const status = data.status;
        const mappedStatus = statusMap[status];
        setMappedVideoStatus(mappedStatus);

        let statusMessage = mappedStatus;
        if (status === "uploaded" && data.progress === 100) {
          statusMessage = "";
          setIsLoading((prev) => ({ ...prev, [data.path]: false }));
        } else {
          setIsLoading((prev) => ({ ...prev, [data.path]: true }));
          if (status !== "completed") {
            statusMessage = `${mappedStatus} ${data.progress || ""}%`;
          } else if (status === "completed") {
            statusMessage = `${mappedStatus} ${data.mergingState || ""}%`;
          }
        }

        return {
          videoId: doc.id,
          thumbnailURL,
          videoURL: data.path,
          status: statusMessage, // Use the corrected status message
          percentage: data.progress,
          generatedTime: generatedTimeInMillis
            ? formatTimestampToReadableDate(generatedTimeInMillis)
            : "-",
          speed: data.speed, // Ensure speed is included here
          fps: data.fps,
          deflicker: data.deflicker,
          removeBlackFrames: data.removeBlackFrames,
          rotation: data.rotation,
          length: formatLength(data.length),
        };
      });
      setThumbnails(thumbnailURLs);
    });

    return () => unsubscribe();
  }, [userId, projectId, thumbnailURL]);

  const handleThumbnailPress = (videoData) => {
    setSelectedVideo(videoData);
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchThumbnail();
  }, [fetchThumbnail]);

  useEffect(() => {
    if (thumbnailURL) {
      const unsubscribe = fetchThumbnails();
      return () => unsubscribe();
    }
  }, [fetchThumbnails, thumbnailURL]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {thumbnails.map((thumbnail, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => handleThumbnailPress(thumbnail)}
          onLongPress={() =>
            handleVideoLongPress({ videoId: thumbnail.videoId })
          } // Add long press handler
        >
          <View style={styles.videoContainer}>
            <View style={styles.thumbnailWrapper}>
              <Image
                source={{ uri: thumbnail.thumbnailURL }}
                style={[
                  styles.thumbnail,
                  isLoading[thumbnail.videoURL] && { opacity: 0.3 }, // Dim the image when loading
                ]}
              />
              {isLoading[thumbnail.videoURL] && (
                <View style={styles.activityIndicatorWrapper}>
                  <ActivityIndicator
                    size="large"
                    color="#0000ff"
                    style={styles.activityIndicator}
                  />
                </View>
              )}
            </View>
            <View style={styles.videoDetails}>
              <Text>{thumbnail.generatedTime}</Text>
              {!thumbnail.status && (
                <>
                  <Text>
                    {thumbnail.length} - {String(thumbnail?.speed)}x -
                    {String(thumbnail?.fps)}fps
                  </Text>
                </>
              )}

              {thumbnail.status && <Text>{thumbnail.status}</Text>}
            </View>
          </View>
        </TouchableOpacity>
      ))}
      <VideoModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        videoData={selectedVideo}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 10,
  },
  videoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  thumbnailWrapper: {
    position: "relative",
  },
  thumbnail: {
    width: 50,
    height: 50,
    aspectRatio: 1, // Ensures the thumbnail is a perfect square
    marginRight: 10,
  },
  activityIndicatorWrapper: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  videoDetails: {
    flex: 1,
  },
  activityIndicator: {
    position: "absolute",
  },
});
