import React, { useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  Switch,
  Image,
  Dimensions,
  ShadowPropTypesIOS,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Image as ExpoImage } from "expo-image";

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  listAll,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import moment from "moment"; // you can use moment to manipulate and format dates

import Header from "../components/Header";
import SettingsSection from "../components/SettingsSection";
import { Alert } from "../components/Alert";
import { formatLastImageTime } from "../components/ProjectCamera/utils/formatLastImageTime";
import {
  convertSecondsToTimeLimit,
  convertTimeLimitToSeconds,
} from "../components/ProjectCamera/utils/convertSecondsToTimeLimit";
import Type from "../components/Type";
import { Asset } from "expo-asset";
import { useNavigation } from "@react-navigation/native";

// Dummy components
const ShootingComponent = ({ isEnabled, updateProjectData }) => {
  const toggleSwitch = () => {
    if (isEnabled) {
      Alert.alert(
        "Confirmation",
        "Are you sure you want to stop the camera?",
        [
          {
            text: "Cancel",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
          {
            text: "OK",
            onPress: () => updateProjectData("status", "stop"),
          },
        ],
        { cancelable: false }
      );
    } else {
      updateProjectData("status", "capturing");
    }
  };

  return <Switch onValueChange={toggleSwitch} value={isEnabled} />;
};

const FlashComponent = ({ isEnabled, updateProjectData }) => {
  const toggleSwitch = () => updateProjectData("flashOn", !isEnabled);
  return <Switch onValueChange={toggleSwitch} value={isEnabled} />;
};

const CameraSummary = ({ projectId, userId }) => {
  const [projectData, setProjectData] = useState({
    status: null,
    flashOn: null,
    interval: null,
    battery: null,
    lastImageTime: null,
    imagesCount: null,
    rotation: null,
  });
  const [lastImageUrl, setLastImageUrl] = useState(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [nextCaptureTime, setNextCaptureTime] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [imageRotation, setImageRotation] = useState(0);

  const navigation = useNavigation();

  useEffect(() => {
    if (lastImageUrl) {
      Image.getSize(lastImageUrl, (width, height) => {
        // Save the image width and height in state
        setImageWidth(width);
        setImageHeight(height);
      });

      // Cache the image
      Asset.loadAsync(lastImageUrl);
    }
  }, [lastImageUrl]);

  useEffect(() => {
    const fetchProjectData = async () => {
      const db = getFirestore();
      const docRef = doc(db, "projects", projectId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProjectData({
          status: docSnap.data().status || "stop",
          flashOn: docSnap.data().flashOn || false,
          interval:
            typeof docSnap.data().timeLimit === "number"
              ? convertSecondsToTimeLimit(docSnap.data().timeLimit)
              : "-",
          battery: docSnap.data().batteryLevel
            ? `${docSnap.data().batteryLevel}%`
            : "-",
          lastImageTime: docSnap.data().lastImageTime
            ? formatLastImageTime(docSnap.data().lastImageTime)
            : "-",
          imagesCount: docSnap.data().imagesCount || "-",
          rotation: docSnap.data().rotation || 0,
        });
        setImageRotation(docSnap.data().rotation || 0);
      } else {
        console.log("No such document!");
      }
    };
    const fetchLastImageTime = async () => {
      const db = getFirestore();
      const imagesCollection = collection(db, "images");
      const q = query(
        imagesCollection,
        where("userId", "==", userId),
        where("projectId", "==", projectId),
        where("excluded", "==", false),
        orderBy("order", "desc"),
        limit(1)
      );
      console.log("params", userId, " - ", projectId);

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const latestImage = querySnapshot.docs[0].data();
        console.log("latestImage", latestImage);
        setLastImageUrl(latestImage.downloadUrl);

        const timestamp =
          latestImage.generation && latestImage.generation / 1000;
        if (timestamp) {
          const formattedTime = formatLastImageTime(timestamp);
          setProjectData((prevData) => ({
            ...prevData,
            lastImageTime: formattedTime,
          }));
        }
      }
    };

    fetchProjectData();
    fetchLastImageTime();
  }, [projectId, userId]);

  useEffect(() => {
    let timer;
    if (projectData.status === "capturing" && projectData.interval) {
      timer = setInterval(() => {
        const now = new Date();
        if (nextCaptureTime) {
          const timeUntilCapture = nextCaptureTime.getTime() - now.getTime();
          if (timeUntilCapture <= 0) {
            takePhoto();
          } else {
            setCountdown(Math.ceil(timeUntilCapture / 1000));
          }
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [projectData.status, projectData.interval, nextCaptureTime]);

  const updateProjectData = async (field, value) => {
    const db = getFirestore();
    const docRef = doc(db, "projects", projectId);
    await updateDoc(docRef, { [field]: value });
    setProjectData((prev) => ({ ...prev, [field]: value }));

    if (field === "status" && value === "capturing") {
      const intervalInMinutes =
        convertTimeLimitToSeconds(projectData.interval) / 60;
      const nextCapture = calculateNextCaptureTime(intervalInMinutes);
      setNextCaptureTime(nextCapture);
    }
  };

  const calculateNextCaptureTime = (intervalInMinutes) => {
    const now = new Date();
    const minutes =
      Math.floor(now.getMinutes() / intervalInMinutes) * intervalInMinutes;
    const nextCapture = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      minutes + intervalInMinutes,
      0,
      0
    );

    if (nextCapture <= now) {
      nextCapture.setMinutes(nextCapture.getMinutes() + intervalInMinutes);
    }

    return nextCapture;
  };

  const takePhoto = useCallback(async () => {
    // Implement the logic to take a photo here
    // This function should update the lastImageTime and imagesCount in the database
    // After taking the photo, calculate and set the next capture time
    const intervalInMinutes =
      convertTimeLimitToSeconds(projectData.interval) / 60;
    const nextCapture = calculateNextCaptureTime(intervalInMinutes);
    setNextCaptureTime(nextCapture);
  }, [projectData.interval]);

  const rows = [
    {
      left: "Shooting",
      right: (
        <ShootingComponent
          isEnabled={projectData.status === "capturing"}
          updateProjectData={updateProjectData}
        />
      ),
    },
    {
      left: "Flash",
      right: (
        <FlashComponent
          isEnabled={projectData.flashOn}
          updateProjectData={updateProjectData}
        />
      ),
    },
    { left: "Interval", right: projectData.interval },
    { left: "Battery", right: projectData.battery },
    { left: "Last Image", right: projectData.lastImageTime },
    { left: "Images count", right: projectData.imagesCount },
    {
      left: (
        <TouchableOpacity
          onPress={() => {
            Alert.alert(
              "Confirmation",
              "Are you sure you want to delete?",
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel",
                },
                {
                  text: "OK",
                  onPress: async () => {
                    const db = getFirestore();
                    const docRef = doc(db, "projects", projectId);
                    await updateDoc(docRef, { deleted: true });
                    console.log("OK Pressed, project marked as deleted");
                    navigation.goBack();
                  },
                },
              ],
              { cancelable: false }
            );
          }}
        >
          <Type style={{ color: "red" }}>Delete project</Type>
        </TouchableOpacity>
      ),
    },
  ];

  const blurhash =
    "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[";

  console.log("imageRotation", imageRotation);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.imageContainer}>
        {lastImageUrl && (
          <View style={styles.imageWrapper}>
            <ExpoImage
              style={[
                styles.image,
                { transform: `rotate(${imageRotation}deg)` },
              ]}
              source={lastImageUrl}
              contentFit="contain"
              transition={200}
              cachePolicy="memory-disk"
            />
          </View>
        )}
        {!lastImageUrl && (
          <View style={styles.noImagesContainer}>
            <Text style={styles.noImagesText}>No images yet</Text>
          </View>
        )}
        {projectData.lastImageTime && (
          <Text style={styles.imageTimestamp}>{projectData.lastImageTime}</Text>
        )}
      </View>
      <SettingsSection rows={rows} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    // padding: 10,
    width: "100%",
    maxWidth: 800,
    alignSelf: "center",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 300,
    overflow: "hidden",
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  imageTimestamp: {
    position: "absolute",
    bottom: 25,
    left: 25,
    color: "white",
    fontSize: 16,
    textShadowColor: "rgba(0, 0, 0, 0.9)", // Increase the opacity of the shadow color
    textShadowOffset: { width: -2, height: 2 }, // Increase the offset to make the shadow larger
    textShadowRadius: 15, // Increase the radius to make the shadow softer
  },
  noImagesText: {
    padding: 20,
    flex: 1,
    color: "white",
    width: "100%",
  },
  noImagesContainer: {
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
    width: "100%",
    backgroundColor: "black",
  },
  dataContainer: {
    // padding: 10,
  },
});

export default CameraSummary;
