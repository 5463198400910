import { useNavigation } from "@react-navigation/native";
import { useEffect, useState, useCallback } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  Switch,
  Picker,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
import { useActionSheet } from "@expo/react-native-action-sheet";

const VideosGallery = ({ route, navigation }) => {
  const { projectId } = route.params;
  const [loading, setLoading] = useState(false);
  const db = getFirestore();
  const { showActionSheetWithOptions } = useActionSheet();
  const [quality, setQuality] = useState("HD");
  const [fps, setFps] = useState(12);
  const [speed, setSpeed] = useState(1);
  const [deflicker, setDeflicker] = useState(false);
  const [removeBlackFrames, setRemoveBlackFrames] = useState(false);

  const onAdd = useCallback(
    async (skipFrameValue) => {
      setLoading(true);

      // Update Firestore
      const projectRef = doc(db, "projects", projectId);
      await setDoc(projectRef, { videoGeneration: "loading" }, { merge: true });

      console.log("--calling", projectId);
      // Make the fetch call with the selected skipFrame value
      fetch(
        `https://us-central1-remotetimelapse-347a5.cloudfunctions.net/imagesToVideo?userId=becL0NRILHQ7jeRpmtAkNDlHLJ72&projectId=${projectId}&skipFrame=${skipFrameValue}&rotation=0`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("There was an error with the fetch call:", error);
        });
    },
    [db, projectId]
  );

  const handleAddPress = () => {
    const options = ["1x", "2x", "8x", "32x", "Cancel"];
    const cancelButtonIndex = 4;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            onAdd(1);
            break;
          case 1:
            onAdd(2);
            break;
          case 2:
            onAdd(8);
            break;
          case 3:
            onAdd(32);
            break;
          default:
            break;
        }
      }
    );
  };

  useEffect(() => {
    const projectRef = doc(db, "projects", projectId);
    const unsubscribe = onSnapshot(projectRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && data.videoGeneration === "done") {
          setLoading(false);
        }
      }
    });

    navigation.setOptions({
      headerRight: () =>
        loading ? (
          <ActivityIndicator size="small" color="black" />
        ) : (
          <Feather
            name="plus-circle"
            size={24}
            color="black"
            onPress={handleAddPress}
          />
        ),
    });

    return () => unsubscribe();
  }, [navigation, loading, onAdd, db, projectId]);

  return (
    <ScrollView>
      <View>
        <Text>Videos</Text>
        <Text>Quality</Text>
        <Picker
          selectedValue={quality}
          onValueChange={(itemValue) => setQuality(itemValue)}
        >
          <Picker.Item label="HD" value="HD" />
          <Picker.Item label="4K" value="4K" />
        </Picker>
        <Text>FPS</Text>
        <Picker
          selectedValue={fps}
          onValueChange={(itemValue) => setFps(itemValue)}
        >
          <Picker.Item label="12" value={12} />
          <Picker.Item label="24" value={24} />
          <Picker.Item label="30" value={30} />
        </Picker>
        <Text>Speed</Text>
        <Picker
          selectedValue={speed}
          onValueChange={(itemValue) => setSpeed(itemValue)}
        >
          <Picker.Item label="1x" value={1} />
          <Picker.Item label="2x" value={2} />
          <Picker.Item label="8x" value={8} />
          <Picker.Item label="32x" value={32} />
        </Picker>
        <Text>Deflicker</Text>
        <Switch value={deflicker} onValueChange={setDeflicker} />
        {/* <Text>Remove Black Frames</Text>
        <Switch
          value={removeBlackFrames}
          onValueChange={setRemoveBlackFrames}
        /> */}
      </View>
    </ScrollView>
  );
};

export default VideosGallery;
