import { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  ScrollView,
  Text,
} from "react-native";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigation } from "@react-navigation/native";
import Button, { ButtonVariant } from "../components/Button";
import FormInput from "../components/FormInput";

const auth = getAuth();

const ForgotMyPassword = () => {
  const navigation = useNavigation();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onChangeEmail = (text) => {
    setEmail(text);
  };

  const onPress = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Alert.alert(
          "Email Sent",
          "Please check your email to reset your password."
        );
        navigation.goBack();
      })
      .catch((error) => {
        const errorMessage = error.message?.replace("Firebase: ", "") ?? "";
        setErrorMessage(errorMessage);
      });
  };

  return (
    <KeyboardAvoidingView style={styles.container} behavior="padding">
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <FormInput
          label="Email"
          placeholder="Enter your email"
          onChangeText={onChangeEmail}
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect={false}
          onSubmitEditing={onPress}
          returnKeyType="done"
        />
        {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
        <View style={styles.buttonsContainer}>
          <Button
            title="Reset Password"
            onPress={onPress}
            variant={ButtonVariant.Primary}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsContainer: {
    marginTop: 20,
    width: "100%",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    width: "80%",
    textAlign: "center",
    marginTop: 5,
  },
});

export default ForgotMyPassword;
