// FloatingButton.js
import React from "react";
import { StyleSheet, TouchableOpacity, Text, ViewStyle } from "react-native";

import Colors from "./Colors";

type FloatingButtonProps = {
  onPress: () => void;
  children: React.ReactNode;
  style?: ViewStyle;
};

const FloatingButton = ({ onPress, children, style }: FloatingButtonProps) => {
  return (
    <TouchableOpacity style={[styles.floatingButton, style]} onPress={onPress}>
      <Text style={styles.floatingButtonText}>{children}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  floatingButton: {
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: Colors.accent2,
    position: "absolute",
    bottom: "10%",
    right: "10%",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
  },
  floatingButtonText: {
    fontSize: 30,
    color: Colors.secondaryText,
    fontWeight: "bold",
  },
});

export default FloatingButton;
