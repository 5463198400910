import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Type, { TypeVariant } from "./Type";

const SettingsRow = ({ left, right, isLast }) => (
  <View style={styles.row}>
    <View style={styles.leftContainer}>
      {typeof left === "string" ? (
        <Type variant={TypeVariant.Body}>{left}</Type>
      ) : (
        left
      )}
    </View>
    <View style={styles.rightContainer}>
      {typeof right === "string" || typeof right === "number" ? (
        <Type variant={TypeVariant.Body}>{right}</Type>
      ) : (
        right
      )}
    </View>
    <View style={[styles.border, isLast ? styles.borderLast : {}]} />
  </View>
);

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
    backgroundColor: "#fff",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: "#d3d3d3",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 15,
  },
  borderLast: {
    marginLeft: 0,
  },
  leftContainer: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
});

export default SettingsRow;
