import { useNavigation } from "@react-navigation/native";
import { Text, View, StyleSheet, Image, Platform } from "react-native";
import { OnboardFlow } from "react-native-onboard";
import Colors from "../components/Colors";
import React from "react";
import IconImage from "../../assets/adaptive-icon.png";
import CameraImage from "../../assets/camera.png";
import RemoteImage from "../../assets/remote.png";

const Onboard = () => {
  const navigation = useNavigation();

  const onDone = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  };
  return (
    <View style={styles.container}>
      <OnboardFlow
        pages={[
          {
            title: "Record",
            subtitle:
              "Use a spare phone to record beautiful timelapse videos of your plants.",
            imageUri:
              Platform.OS === "web"
                ? undefined
                : Image.resolveAssetSource(require("../../assets/camera.png"))
                    .uri,
            imageComponent:
              Platform.OS === "web" ? (
                <Image
                  source={CameraImage}
                  style={{ width: 400, height: 400 }}
                />
              ) : undefined,
          },
          {
            title: "Monitor",
            subtitle:
              "Monitor your videos using another phone or through www.ptl.camera",
            imageUri:
              Platform.OS === "web"
                ? undefined
                : Image.resolveAssetSource(require("../../assets/remote.png"))
                    .uri,
            imageComponent:
              Platform.OS === "web" ? (
                <Image
                  source={RemoteImage}
                  style={{ width: 400, height: 400 }}
                />
              ) : undefined,
          },
          {
            title: "Share",
            subtitle:
              "Share your timelapse videos with friends and family on social media",
            imageUri:
              Platform.OS === "web"
                ? undefined
                : Image.resolveAssetSource(require("../../assets/camera.png"))
                    .uri,
            imageComponent:
              Platform.OS === "web" ? (
                <Image
                  source={CameraImage}
                  style={{ width: 400, height: 400 }}
                />
              ) : undefined,
          },
        ]}
        primaryButtonStyle={{
          backgroundColor: Colors.accent1,
        }}
        primaryButtonTextStyle={{
          color: Colors.secondaryText,
        }}
        onDone={onDone}
        type="inline" // Change to either 'fullscreen', 'bottom-sheet', or 'inline'
      />
    </View>
  );
};

export default Onboard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...(Platform.OS === "web"
      ? {
          maxWidth: 800,
          alignSelf: "center",
          margin: 20,
          backgroundColor: Colors.white,
          borderRadius: 10,
        }
      : {}),
  },
});
