// const sixMinutesInMilliseconds = 6 * 60 * 1000;
const thirtySecondsInMilliseconds = 30 * 1000;
// const fifteenSecondsInMilliseconds = 15 * 1000;
export const CAMERA_WARMUP_SECONDS = 10;
// const fiveSecondsInMilliseconds = 5 * 1000;
export const FOLDER_NAME = "out4";
export const TIMELAPSE_INTERVAL = thirtySecondsInMilliseconds;
export const GENERATE_VIDEO_EVERY_X_FRAME = 20;

// eslint-disable-next-line prefer-const
export let MODE = "prod";

export const BASE_URL =
  MODE === "dev"
    ? "http://localhost:5001/ptlcamera/us-central1"
    : "https://us-central1-ptlcamera.cloudfunctions.net";
