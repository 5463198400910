import SettingsRow from "./SettingsRow";

const SettingsSection = ({ rows }) =>
  rows.map((row, index) => (
    <SettingsRow
      key={index}
      left={row.left}
      right={row.right}
      isLast={index === rows.length - 1}
    />
  ));

export default SettingsSection;
