import { Alert } from "../components/Alert";
import { MODE, BASE_URL } from "../config/constants";

const deleteVideo = async ({ videoId, onClose }) => {
  const url = `${BASE_URL}/deleteVideoRequest?videoId=${videoId}`;

  try {
    const response = await fetch(url, { method: "POST" });
    const data = await response.json();
    console.log("data", data);
    onClose(); // Close the modal after deletion
  } catch (e) {
    console.error("Failed to delete video:", e);
    Alert.alert("Delete video failed", e.message);
  }
};

export default deleteVideo;
