import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import VideoPlayer from "./VideoPlayer";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { Alert } from "./Alert";
import deleteVideo from "../utils/deleteVideo";
import { handleShare } from "../utils/shareVideo"; // Import the handleShare function

const VideoModal = ({ isVisible, onClose, videoData }) => {
  const videoUrl = videoData?.videoURL;
  const [signedVideoUrl, setSignedVideoUrl] = useState(null);
  const [error, setError] = useState(null);
  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    if (isVisible) {
      setError(null);
    }
  }, [isVisible]);

  const handleHamburgerPress = () => {
    const options = ["Share", "Delete", "Cancel"];
    const destructiveButtonIndex = 1;
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      async (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            // Share logic here
            try {
              const storage = getStorage();
              const videoRef = ref(storage, "/" + videoData?.videoURL);
              const url = await getDownloadURL(videoRef);
              handleShare(url); // Use the handleShare function
            } catch (err) {
              console.error("Error fetching video URL:", err);
              setError(err);
            }
            break;
          case 1:
            // Delete logic here
            deleteVideo({ videoId: videoData.videoId, onClose });
            break;
          case cancelButtonIndex:
            break;
        }
      }
    );
  };

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const storage = getStorage();
        const videoRef = ref(storage, "/" + videoData?.videoURL);
        const url = await getDownloadURL(videoRef);
        setSignedVideoUrl(url);
      } catch (err) {
        console.error("Error fetching video URL:", err);
        setError(err);
      }
    };
    if (videoData) {
      fetchVideoUrl();
    }
  }, [videoData]);

  if (!videoData || videoData === null) {
    return null;
  }
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={isVisible}
      onRequestClose={onClose}
      presentationStyle="pageSheet"
    >
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.hamburgerMenu}
          onPress={handleHamburgerPress}
        >
          <MaterialIcons name="menu" size={36} color="grey" />
        </TouchableOpacity>
        {error && <Text style={styles.errorText}>Error loading video</Text>}
        <VideoPlayer key={0} videoPath={signedVideoUrl} />

        <View style={styles.details}>
          {videoData?.speed && <Text>Speed: {videoData.speed}x</Text>}
          {videoData?.status && <Text>Status: {videoData.status}</Text>}
          {videoData?.length && <Text>Length: {videoData.length} seconds</Text>}
          {videoData?.generatedTime && (
            <Text>Generated Time: {videoData.generatedTime}</Text>
          )}
          {videoData?.fps && (
            <Text>Frames Per Second: {videoData.fps} fps</Text>
          )}
        </View>
        <TouchableOpacity style={styles.closeButton} onPress={onClose}>
          <Ionicons
            name="close"
            size={36}
            color={signedVideoUrl ? "grey" : "black"}
          />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  hamburgerMenu: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
    padding: 20,
  },
  iconStyle: {
    textShadowOffset: { width: 5, height: 2 },
    shadowColor: "black",
    shadowOpacity: 1,
    // iOS
    shadowOffset: {
      width: 0, // These can't both be 0
      height: 1, // i.e. the shadow has to be offset in some way
    },
    elevation: 6,
  },
  video: {
    width: "100%",
    height: 300,
  },
  details: {
    paddingTop: 20,
    paddingLeft: 20,
    alignItems: "flex-start",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 60,
    padding: 20,
  },
  errorText: {
    color: "red",
    marginBottom: 10,
  },
});

export default VideoModal;
